import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Fab, useScrollTrigger } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Header1 from '../../../Page/Header1';
import Footer from '../../../Page/Footer';
import Cpage2 from '../content/Cpage2';
import Page1 from '../content/Page1';
import Page3 from './Page3';
import Fade from '@mui/material/Fade';

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <div 
        onClick={handleClick} 
        role="presentation" 
        style={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16, 
          zIndex: 1000 // Ensure it stays on top
        }}
      >
        {children}
      </div>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Contentpage = (props) => {
  return (
    <Grid container direction="column">
      <div id="back-to-top-anchor" />
      <Header1 />
      <Page3 />
      <Page1 />
      <Cpage2 />
      <Footer />
      <ScrollTop {...props}>
        <Fab size='large' aria-label='scroll back to top'>
          <ArrowUpwardIcon sx={{ fontSize: '30px' }} />
        </Fab>
      </ScrollTop>
    </Grid>
  );
};

export default Contentpage;
