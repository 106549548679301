import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useNavigate } from "react-router-dom";

const Course = [
  { id: 1, course: "Digital Marketing" },
  { id: 2, course: "Web development" },
  { id: 3, course: "Graphic Designing" },
  { id: 4, course: "SEO" },
  
];

const Subscribe = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [course, setSelectedCourse] = useState("Select Course");
  const [courseError, setCourseError] = useState(false);

  const handleCourseChange = (e) => {
    const value = e.target.value;
    setSelectedCourse(value);
    setCourseError(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    if (email === '') {
      setEmailError(true);
      return;
    }
    if (phoneNumber === '') {
      setPhoneNumberError(true);
      return;
    }
    if (course === 'Select Course') {
      setCourseError(true);
      return;
    }

    // Reset form fields after submission
    setEmail('');
    setPhoneNumber("");
    setSelectedCourse("Select Course");

    // Show success message
    showSnackbar("Thank you for subscribing!", "success");
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarSeverity(severity);
  };

  return (
    <div
      xs={12}
      sm={4}
      md={4}
      style={{
       
        padding: "30px",
        backgroundColor: "white",
        height: "auto",
        borderBottom: "1px solid #ccc",
        background: "#f4f4f5",
      }}
    >
      <Container>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: { xs: "0", sm: "10px", md: "20px" },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "13px",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                width: "100%",
                fontWeight: "600",
                fontSize: { xs: "20px", sm: "25px", md: "25px" },
                lineHeight: "34px",
                color: "#333333",
                textAlign: "center",
              }}
            >
              Subscribe to Our Newsletter
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                fontWeight: "400",
                fontSize: { xs: "10px", sm: "12px", md: "16px" },
                lineHeight: { xs: "25px", sm: "30px", md: "30px" },
                color: "#95959B",
                textAlign: "center",
              }}
            >
              Subscribe to Our Newsletter for Exclusive Updates and Opportunities
            </Typography>
          </Grid>
        </Grid>
  
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "20px",
                borderRadius: "6px",
                margin: { xs: "5px", md: "20px" },
                my: 2,
                height: "55px", 
              }}
              placeholder="Enter your email"
              value={email}
              autoComplete="off"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              error={emailError}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "20px",
                borderRadius: "6px",
                margin: { xs: "5px", md: "20px" },
                my: 2,
                height: "55px", 
              }}
              placeholder="Enter your phone number"
              value={phoneNumber}
              autoComplete="off"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setPhoneNumberError(false);
              }}
              error={phoneNumberError}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Select
              value={course}
              onChange={handleCourseChange}
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "16px",
                borderRadius: "6px",
                margin: { xs: "5px", md: "20px" },
                my: 2,
              }}
              error={courseError}
              style={{
                color: course === "Select Course" ? "gray" : "black",
              }}
            >
              <MenuItem value="Select Course" disabled>
                Select Course
              </MenuItem>
              {Course.map((course) => (
                <MenuItem key={course.id} value={course.course}>
                  {course.course}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                background: "#1D1A57",
                borderRadius: "5px",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
                margin: { xs: "5px", md: "20px" },
                height: "55px", 
                '&:hover': {
                  background: "#1D1A57",
                }
              }}
              fullWidth
            >
              Subscribe Now&nbsp;&nbsp;<SendOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "auto" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Subscribe;
