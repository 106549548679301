import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BadgeIcon from '@mui/icons-material/Badge';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const Courses = () => {

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Our email marketing courses are taught by industry professionals with years of practical experience in digital marketing. Learn directly from experts about the latest trends and best practices in email marketing, including campaign strategy, automation, and segmentation."
    },
    {
      icon: <BadgeIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Certification and Career Support",
      paragraph: "Earn industry-recognized certifications that will enhance your credibility in the workplace. Our career support services, including resume writing, interview coaching, and job placement assistance, are designed to help you succeed in the field of email marketing."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Real-World, Hands-On Education",
      paragraph: "We focus on hands-on training rather than just theory. By working on live email campaigns, creating email lists, and optimizing open and click rates, you’ll gain the practical skills needed to implement successful email marketing strategies."
    },
    {
      icon: <OfflinePinIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Skill Development Assurance",
      paragraph: "Our email marketing courses are designed for all skill levels, from beginners to advanced professionals. You can customize your learning experience to suit your expertise, helping you progress in your email marketing career with confidence."
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Updated Curriculum",
      paragraph: "Our course materials are continuously updated to reflect the fast-changing nature of email marketing and digital communication. Stay ahead by learning the newest tools, strategies, and trends in the industry."
    },
    {
      icon: <LeaderboardIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Comprehensive Digital Marketing Focus",
      paragraph: "Our curriculum covers key aspects of email marketing, such as list building, campaign design, A/B testing, analytics, and automation. You’ll gain a complete understanding of how email marketing fits into the broader digital marketing ecosystem."
    },
    {
      icon: <LocalLibraryIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Customized Learning Paths",
      paragraph: "We offer flexible course tiers that allow you to choose the right path for your expertise level and career goals. Whether you’re just starting or looking to refine your skills, you can tailor your educational journey in email marketing."
    },
    {
      icon: <MenuBookIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Flexible Learning Options",
      paragraph: "With both online and in-person options available, you can adapt your learning experience to fit your schedule. Our flexible learning choices ensure you can learn at your own pace while balancing other responsibilities."
    },
    
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
      }}
    >
      <Container sx={{ minHeight: '80vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center">
          Why SEO Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '40px',
          }}
        >
          Few Reasons to Student Select Us
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6}>          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px',fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
