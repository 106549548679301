import React, { useEffect, useState } from 'react'
// import Header1 from './Header';
import Banner from './Banner';
import Aboutdigital from './Aboutdigital';
import Services from './Services';
import Footer from './Footer';
import Process from './Process';
import Choose from './Choose';
import Expertize from './Expertize';
import Updates from './Updates';
import Review from './Review';
import PropTypes from 'prop-types';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Grid , Fab, useScrollTrigger} from '@mui/material';
import Header1 from './Header1';
import StatsSection from './StatsSection';
import Subscribe from './Subscribe';
import AutoPopup from '../../component/Page/Autopopup';
import Internship from './Internship';
// import Functionality from './Functionality';
import Fade from '@mui/material/Fade';

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <div 
        onClick={handleClick} 
        role="presentation" 
        style={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16, 
          zIndex: 1000 // Ensure it stays on top
        }}
      >
        {children}
      </div>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Home = (props) => {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);

  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <Grid sx={{ overflowX: 'hidden', }}>
      <div id="back-to-top-anchor" />
      <Header1 />
      <Banner />
      {/* <Ripple/> */}
      <Aboutdigital />
      <Choose />
      <Services />
      <Expertize />
      <Internship/>
      <Process />
      <Review />
      <StatsSection />
      <Updates />
      <Subscribe />
      <ScrollTop {...props}>
        <Fab size='large' aria-label='scroll back to top'>
          <ArrowUpwardIcon sx={{ fontSize: '30px' }} />
        </Fab>
      </ScrollTop>
      <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}
        leadFlag="Organic"
      />
      <Footer />

      {/* <Functionality/> */}
    </Grid>
  )
}

export default Home
