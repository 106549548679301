import React, { useEffect } from 'react';
import { Container, Grid, Typography, Box, Card, CardContent, CardMedia, Grow, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import seo from '../../../assets/wt1.jpeg';
import social from '../../../assets/social11.jpg';
import email from '../../../assets/email11.jpg';
import meta from '../../../assets/meta11.jpg';
import vedio from '../../../assets/videomarketing1-removebg-preview.png';
import ppc from '../../../assets/payper.jpg';
import influencer from '../../../assets/influe-removebg-preview.png';
import content from '../../../assets/content1.jpg';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const courseData = [
  { id: 1, title: 'SEO', image: seo, description: 'Boost your online visibility and drive traffic with expert SEO marketing solutions.', path: '/seo-pages' },
  { id: 2, title: 'Content Marketing', image: content, description: 'Engage your audience with quality content that builds brand awareness.', path: '/content-pages' },
  { id: 3, title: 'PPC', image: ppc, description: 'Optimize ads to drive immediate, targeted traffic.', path: '/ppc-pages' },
  { id: 4, title: 'Meta ADS', image: meta, description: 'Increase engagement with tailored ads on Meta platforms.', path: '/meta-pages' },
  { id: 5, title: 'Email Marketing', image: email, description: 'Reach customers directly with personalized email campaigns.', path: '/email-pages' },
  { id: 6, title: 'Social Media', image: social, description: 'Grow your social media presence with strategic engagement.', path: '/social-pages' },
];

const Courses = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#e3e6e4',
        overflowX: 'hidden',
      }}
    >
      <Container sx={{ minHeight: '80vh' }}>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 2,
            mt: 5,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: { xs: '1.8rem', sm: '2.5rem' },
          }}
        >
          Our Popular Digital Courses
        </Typography>

        <Grid container rowSpacing={6} columnSpacing={4} sx={{ backgroundColor: '#e3e6e4', padding: '60px 30px' }}>
          {courseData.map((course, index) => (
            <Grow in={true} timeout={(index + 1) * 1000} key={course.id}>
              <Grid item xs={12} sm={6} md={4} sx={{ mt: { xs: -3 } }}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: 4,
                    padding: 1,
                    height: isTablet ? '450px' : '400px',
                    width: '90%',
                    backgroundColor: 'white',
                    margin: '0 auto',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={course.image}
                    alt={course.title}
                    sx={{ width: '100%', height: '200px', marginBottom: 1, backgroundColor: '#faf9f0' }}
                  />

                  <CardContent sx={{ width: '100%', paddingBottom: 1 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, fontFamily: 'sans-serif', fontSize: '1.2rem', height: '30px' }}>
                      {course.title}
                    </Typography>
                    <Divider />
                    <Typography variant="body2" color="black" mt={1} sx={{ fontSize: '16px', height: '40px' }}>
                      {course.description}
                    </Typography>
                    <Button
                      variant='contained'
                      sx={{ borderRadius: '30px', mt: 5, padding: '4px 12px', fontSize: '0.85rem', backgroundColor: '#004e6a' }}
                      onClick={() => navigate(course.path)}
                    >
                      Know More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grow>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
