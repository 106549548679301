import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Paper, useMediaQuery, Typography, ThemeProvider, createTheme } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CommitIcon from '@mui/icons-material/Commit';
import VerifiedIcon from '@mui/icons-material/Verified';
import { styled } from '@mui/system';

function RotatingCube({ position, color }) {
  const meshRef = useRef();
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation + 0.01);
    }, 16);
    return () => clearInterval(interval);
  }, []);

  return (
    <mesh ref={meshRef} rotation={[rotation, rotation, 0]} position={position}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
}

function generateRandomPosition() {
  const maxPos = 40;
  const minPos = -20;
  return [
    Math.random() * (maxPos - minPos) + minPos,
    Math.random() * (maxPos - minPos) + minPos,
    Math.random() * (maxPos - minPos) + minPos,
  ];
}

const Process = () => {
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1024px)');

  // Create an array of 100 random cubes
  const cubes = [];
  for (let i = 0; i < 100; i++) {
    cubes.push({
      position: generateRandomPosition(),
      color: `hsl(${Math.random() * 360}, 100%, 50%)`,
    });
  }

  const canvasStyle = {
    position: 'absolute',
    top: 1,
    left: 1,
    right:1,
    bottom:1,
    width: '100%',
    height: '100%',
    zIndex: -5,
  };
  const containerStyle = {
    position: 'relative',
    width: '100%',
    minHeight: isTablet ? '50vh' : '80vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    backgroundColor: '#f5f3f0',
  };

  const steps = [
    {
      title: 'Select a Project',
      description: 'We assist in identifying your needs and setting clear objectives.',
      icon: <CheckCircleIcon fontSize="large" sx={{ color: 'aquamarine' }} />,
    },
    {
      title: 'Project Analysis',
      description: 'We conduct a thorough analysis and provide strategic planning.',
      icon: <AccountTreeIcon fontSize="large" sx={{ color: 'aquamarine' }} />,
    },
    {
      title: 'Plan & Execute',
      description: 'We develop detailed plans and execute them efficiently.',
      icon: <CommitIcon fontSize="large" sx={{ color: 'aquamarine' }} />,
    },
    {
      title: 'Deliver Results',
      description: 'We ensure the delivery of high-quality and timely results.',
      icon: <VerifiedIcon fontSize="large" sx={{ color: 'aquamarine' }} />,
    },
  ];

  const theme = createTheme();

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(5), // Increased padding
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '200px', // Increased height for more space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'box-shadow 0.3s',
    boxShadow: theme.shadows[2],
    borderRadius: '10px',
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <Box style={containerStyle}>
        <Grid container sx={{ zIndex: 1, textAlign: 'center' }}>
          <Canvas style={canvasStyle}>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {cubes.map((cube, index) => (
              <RotatingCube key={index} position={cube.position} color={cube.color} />
            ))}
            <OrbitControls />
          </Canvas>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                marginBottom: 4,
                padding:10,
                height:'60%'
              }}
            >
              <Typography variant="h4" sx={{ fontFamily: 'sans-serif', color: 'green', fontWeight: 400, marginTop: 2, marginBottom: 2 }}>
                Work Process
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: 'sans-serif', fontWeight: 800, marginBottom: 3 }}>
                How We Help <span style={{ color: 'green' }}>You!</span>
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', mb: 10 , mr:6, ml:3}}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Item>
                  <Box>
                    <Box sx={{ marginBottom: 2 }}>{step.icon}</Box>
                    <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, color: 'black' }}>
                      {step.title}
                    </Typography>
                    <Typography>{step.description}</Typography>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Process;
