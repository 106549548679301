import React, { useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import Slider from 'react-slick';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import CodeIcon from '@mui/icons-material/Code';
import AdsClickIcon from '@mui/icons-material/AdsClick';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/joy/Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner2 from '../../../assets/banner2.png';
import { styled } from '@mui/system';


const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .slick-prev, & .slick-next': {
    zIndex: 1,
    width: 30,
    height: 30,
    '&:before': {
      fontSize: 30,
      color: 'white',
    },
  },
  '& .slick-prev': {
    left: -40,
    [theme.breakpoints.down('sm')]: {
      left: -20,
    },
  },
  '& .slick-next': {
    right: -40,
    [theme.breakpoints.down('sm')]: {
      right: -20,
    },
  },
}));

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      title: 'Search Engine Optimization (SEO) ',
      icon: <DesignServicesIcon />,
      description: 'Boost the organic traffic to your website by implementing on-page SEO methods. quality backlink building and targeted keyword strategies.                    ',
    },
    {
      title: 'Social Media Management',
      icon: <CampaignIcon />,
      description: 'Social media campaigns on sites like Facebook and Twitter to increase customer interaction brand exposure and community building for your company.',
    },
    {
      title: ' Content Creation',
      icon: <BusinessCenterIcon />,
      description: 'High-quality content customized to your audience that including blog posts, articles, and videos that resonate with your brand’s voice and enhance your online presence.',
    },
    {
      title: 'Googel ADS (PPC)',
      icon: <AdsClickIcon />,
      description: 'Develop robust and scalable software solutions to meet your needs.',
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        // marginTop: 10,
        position: 'relative',
        backgroundImage: `url(${banner2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '90vh',
      }}
    >
     
      <Container>
        <Grid container sx={{ paddingTop: 10 }} spacing={2}>
          <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'sans-serif',
                fontSize: 20,
                color: 'green',
                fontWeight: 600,
              }}
            >
              OUR SERVICES
            </Typography>

            <Typography
              variant="h4"
              sx={{
                fontFamily: 'sans-serif',
                fontWeight: 800,
                marginTop: 2,
                color: '#fff',
              }}
            >
              We Provide Expert Solutions <br /> For <span style={{ color: 'green' }}>Your Success</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ flexDirection:'column', justifyContent: { xs: 'center', md: 'flex-end' } }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#5e5028',
                color: '#fff',
                fontWeight: 'bold',
                padding: { xs: '8px 16px', md: '4px 8px' }, // Adjust padding for desktop view
                textTransform: 'none',
                fontSize: 14,
                borderRadius: '50px',
                height: { md: '40px' },
                marginTop: { md: '20px' },
                '&:hover': {
                  backgroundColor: '#83bfc9',
                },
              }}
            >
              VIEW ALL Courses
              <ArrowRightIcon sx={{ marginLeft: 1 }} />
            </Button>
            {/* <Button
  color="danger"
  disabled={false}
  loading={false}
  onClick={function(){}}
  size="lg"
  variant="soft"
  sx={{borderRadius: '20px', width:'50%', height:'10%'}}
>Save
  </Button> */}
            
          </Grid>
        </Grid>

        <StyledSlider {...settings} sx={{ marginTop: 4 }}>
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Card
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s',
                  margin: '10px',
                  textAlign: 'center',
                  padding: 2,
                  boxShadow: 3,
                  backgroundColor: hoveredCard === index ? '#7C4DFF' : '#fff',
                  '&:hover': {
                    boxShadow: 6,
                    backgroundColor: '#96d6a2',
                  },
                }}
              >
                <CardMedia>
                  <Grid sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                    <Grid
                      sx={{
                        width: 60,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: hoveredCard === index ? '#fff' : '#51915d',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'background-color 0.3s',
                      }}
                    >

                      {React.cloneElement(card.icon, {
                        sx: {
                          fontSize: 30,
                          color: hoveredCard === index ? '#51915d' : '#fff',
                          transition: 'color 0.3s',
                        },
                      })}
                    </Grid>
                  </Grid>
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: hoveredCard === index ? '#1f2620' : '#7f3ff2',
                      transition: 'color 0.3s',
                      marginBottom: 1,
                      height:80,
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: hoveredCard === index ? '#1f2620' : '#000',
                      transition: 'color 0.3s',
                      height:90,
                    }}
                  >
                    {card.description}
                  </Typography>
                </CardContent>
                <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px', background: "linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)",
                    backgroundColor: "white",
                    mt: 2,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#3f51b5',
                        color: '#E0E7FF',
                    },}}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
              </Card>
            </Grid>
          ))}
        </StyledSlider>
      </Container>
    </Box>
  );
};

export default Services;
