import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
// import certifiedIcon from '../../assets/banner1.png';
import seo from '../../../assets/3.jpg';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'; 
import socialmedia from '../../../assets/2.jpg'; 
import googleads from '../../../assets/1.jpg';
import content from '../../../assets/4.jpg';
import email from '../../../assets/5.jpg';
import { useNavigate } from 'react-router-dom';

const Servicesall = () => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate=useNavigate();

  const handelClick =() => {
    navigate('/allseo')
}
window.scrollTo(0, 0);
const handelOpen =() => {
  navigate('/allsocial')
}
window.scrollTo(0, 0);
  return (
    
    <Container sx={{ py: 8}} >
      <Box>
        <Typography variant='h2' sx={{fontSize: {xs:30, sm:30, md:40}, color:"#907394", fontWeight:600, textAlign:'center'}}> Elevate Your Brand </Typography>
        <Typography variant='h5' sx={{fontSize: {xs:10, sm:20, md:30}, color:"black",  textAlign:'center', mt:1} }> Strategic marketing that leads to long-term growth</Typography>
      </Box>
        {/* SEO */}
      <Grid container spacing={4} justifyContent="center" alignItems="center" mt={2}>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h4">Search Engine Optimization  <span style={{ color: 'green' }}>(SEO)</span> </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      Our SEO services are intended to raise the visibility of your website by raising its search engine rating on platforms like Google. To boost organic traffic. we use technical SEO techniques on- and off-page optimization, and sophisticated keyword analysis. We make sure your website stays competitive attracts relevant traffic and turns visitors into devoted customers by providing ongoing monitoring and upgrades.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 4,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: 'black',
                    },}} onClick={handelClick}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      
      </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , }}>
          <img
            src={seo}
            alt="About Us"
            style={{ width: '100%', height:'400px', borderRadius: '80px' }}
          />
          
        </Grid>
        
      </Grid>

      {/* Social media */}
      <Grid container spacing={4} justifyContent="center" alignItems="center" mt="50px">
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={socialmedia}
            alt="About Us"
            style={{ width: '100%', height:'400px', borderRadius: '80px' }}
          />
          
        </Grid>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h3">Social Media Marketing</Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      By creating social media campaigns that are specifically customized to your target audience we help you increase interaction and brand exposure. We create a devoted online community around your business by using platform-specific tactics and creating interesting content. With the help of our social media marketing your message will effectively reach the proper audience on all the key platforms, including Facebook, Instagram, Twitter, and LinkedIn.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 4,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                      background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                                              color: 'black',
                    },}} onClick={handelOpen}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      </Grid>
        
        
      </Grid>
{/* igital marketing agency  */}
      <Grid container spacing={4} justifyContent="center" alignItems="center" mt='40px'>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h3">Digital Marketing </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      Our digital marketing company specializes in developing customized plans that improve the online visibility of your company. <br/>Through the use of cutting-edge strategies, we can connect with your target audience, increase traffic, and boost conversions through anything from SEO and social media management to pay-per-click advertising and content marketing. Our committed staff collaborates to make sure your brand is noticeable in the digital sphere and achieves quantifiable outcomes that support your corporate objectives.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 4,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: 'black',
                    },}}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={googleads}
            alt="About Us"
            style={{ width: '100%', height:'400px',borderRadius: '80px' }}
          />
          
        </Grid>
        
      </Grid>

      {/* Web Development */}
      <Grid container spacing={4} justifyContent="center" alignItems="center" mt="50px">
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={content}
            alt="About Us"
            style={{ width: '100%', height:'400px', borderRadius: '40px' }}
          />
          
        </Grid>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h3">Web Development</Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      In order to meet your business's objectives, we provide comprehensive web development solutions that guarantee a reliable and user-friendly online presence. Our talented development team builds responsive, aesthetically pleasing websites that work flawlessly on all platforms by utilizing the newest technologies. We put performance, security, and scalability first when developing anything from corporate websites to e-commerce platforms. This allows your company to succeed in the digital sphere while giving your customers an easy-to-use experience.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 4,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: 'black',
                    },}}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      </Grid>
        
        
      </Grid>
      {/* Graphic design */}
      <Grid container spacing={4} justifyContent="center" alignItems="center" mt='40px'>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h3">Graphic Design Services </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      In order to meet your business's objectives, we provide comprehensive web development solutions that guarantee a reliable and user-friendly online presence. Our talented development team builds responsive, aesthetically pleasing websites that work flawlessly on all platforms by utilizing the newest technologies. We put performance, security, and scalability first when developing anything from corporate websites to e-commerce platforms. This allows your company to succeed in the digital sphere while giving your customers an easy-to-use experience.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 4,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: 'black',
                    },}}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={email}
            alt="About Us"
            style={{ width: '100%', height:'400px', borderRadius: '80px' }}
          />
          
        </Grid>
        
      </Grid>

      {/* Content Marketing */}
      {/* <Grid container spacing={4} justifyContent="center" alignItems="center" mt="50px">
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={content}
            alt="About Us"
            style={{ width: '80%', height:'120%', borderRadius: '40px' }}
          />
          
        </Grid>
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify'}}>
      <Typography variant="h3">Content Marketing</Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', maxWidth: '600px',textAlign: 'justify' }}>
      By creating social media campaigns that are specifically customized to your target audience we help you increase interaction and brand exposure. We create a devoted online community around your business by using platform-specific tactics and creating interesting content. With the help of our social media marketing your message will effectively reach the proper audience on all the key platforms, including Facebook, Instagram, Twitter, and LinkedIn.
      </Typography>
      <Button variant="contained"
                  sx={{ marginBottom: '30px', height:'50px', borderRadius:'20px', background: "linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)",
                    backgroundColor: "#fff",
                    color:'white',
                    mt: 2,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: 'black',
                    },}}
                >

                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
      </Grid>
        
        
      </Grid> */}
     
    </Container>
  );
};

export default Servicesall;
