import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Button, Card, CardContent } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
// import certifiedIcon from '../../assets/banner1.png';
import certifiedIcon from '../../assets/expert123.jpeg';

const Expertize = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Container sx={{ py: 6,}}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6} sx={{ textAlign:{md:'left', xs:'center'}, padding:3}}>
      <Typography variant="h2" sx={{fontSize:{md:'2 rem' , xs:'40px', fontWeight:600, color:'#4e8c96'}}}>Our Expertize </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify', lineHeight:'30px' }}>
      With years of experience in the field of digital marketing, our team of experts has developed their knowledge and skills 
      to stay ahead of the constantly changing digital landscape. We employ a broad range of skills to revitalize your company 
      in the digital age from programmatic strategies to Performance Marketing dexterity. 
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' , lineHeight:'30px'}}>
      From search engine optimization and 
      App Store Optimization to skillfully navigating social media realms and from creating innovative Creative Formats to 
      coordinating successful Paid Media campaigns. You may be confident that we are your reliable guides through the complexities 
      of the internet guiding significant results for your company.
      </Typography>
      </Grid>
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <img
            src={certifiedIcon}
            alt="About Us"
            style={{ width: '100%', borderRadius: '30px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: '#83bfc9',
              color: 'white',
              width: {md:'160px',xs:'100px'},
              height: {md:'160px',xs:'100px'},
              position: 'absolute',
              top: {md:'350px',xs:'190px'},
              left: 'calc(70% - 60px)',
              transition: 'transform 0.3s ease',
              transform: isHovered ? 'translateY(-20px)' : 'translateY(0)',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography variant="h2" component="span" sx={{ fontWeight: 800,fontSize:{xs:'30px'} }}>
              5+
            </Typography>
            <Typography variant="body2" component="span" sx={{ fontWeight: 800,fontSize:{xs:'10px'} }}>
              Years Experience
            </Typography>
          </Box>
        </Grid>
        
      </Grid>
    </Container>
  );
};

export default Expertize;
