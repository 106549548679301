import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider } from '@mui/material';
import banner1 from '../../../../assets/email/audience seg.jpg';
import banner2 from '../../../../assets/email/personalised content.jpg';
import banner3 from '../../../../assets/email/automated campaign.jpg';
import banner4 from '../../../../assets/email/a_b testing.jpg';
import banner5 from '../../../../assets/email/Emial deliveriabilty.jpg';

const services = [
    {
        value: 'Audience Segmentation ',
        title: 'Audience Segmentation ',
        description: 'Create valuable, relevant content to attract and retain a clearly defined audience, driving profitable customer actions.',
        points1: '➢ Superior, educational material establishes your brand as a reliable authority.',
        points2: '➢ By motivating readers to take action, strategic content raises conversion rates.',
        points3: '➢ Publishing trends and insights regularly distinguishes your brand from rivals.',
        points4: '➢ We can guide potential consumers through the buyer’s journey.',
        image: banner1,
    },
    {
        value: 'Personalization Content ',
        title: 'Personalization Content ',
        description: 'Drive targeted traffic and generate leads through paid ads on platforms like Google Ads.',
        points1: '➢ Find high-intent keywords that complement your target market.',
        points2: '➢ Thorough control over targeting, bidding tactics, and ad budget.',
        points3: '➢ Use analytics to track campaign performance.',
        image: banner2,
    },
    {
        value: 'Automated Campaigns ',
        title: 'Automated Campaigns',
        description: 'Communicate directly with customers through personalized email campaigns.',
        points1: '➢ Create customized email campaigns for high engagement.',
        points2: '➢ Deliver tailored content using sophisticated segmentation.',
        points3: '➢ Re-engagement campaigns can target dormant subscribers.',
        image: banner3,
    },
    {
        value: 'A/B Testing and Optimization',
        title: 'A/B Testing ',
        description: 'Meta Ads empower brands to build awareness and drive conversions.',
        points1: '➢ Use Meta’s sophisticated targeting options.',
        points2: '➢ Create, track, and refine campaigns on Facebook and Instagram.',
        points3: '➢ Strategically allocate funds for maximum return.',
        image: banner4,
    },
    {
        value: ' Email Deliverability',
        title: ' Email Deliverability  ',
        description: 'Collaborate with influencers to enhance brand credibility.',
        points1: '➢ Find and work with influencers who share your values.',
        points2: '➢ Collaborate with influencers to create genuine content.',
        points3: '➢ Cultivate relationships with influencers for brand loyalty.',
        image: banner5,
    },
    
];

const Page2 = () => {
    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const currentService = services.find(service => service.value === value);

    return (
        <Container sx={{padding:'50px 10px'}}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
                    }}
                >
                    Email Campaigns for Maximum ROI
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '25px' , md:'20px'}, color: "#3f4040", textAlign: 'center', mt: 1 }}
                >
                    Discovering Email Marketing-A Complete Course for Contemporary Marketers
                </Typography>

                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: { xs: '10px', md: '10px 10px' }, justifyContent: 'center' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }} // Responsive wrapping of tabs
                >
                    {services.map((service) => (
                        <Tab
                            key={service.value}
                            value={service.value}
                            label={service.title}
                            sx={{ fontSize: { xs: '12px', sm: '16px', md:'16px' }, fontFamily: 'sans-serif' }} // Responsive font size
                        />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '40px 100px' } }}>
                    <Grid item xs={12} md={6} mt={5}>
                        <Fade in>
                            <div>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4b87a6', height: 60 ,fontSize:'30px'}}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height: 60 }}>{currentService.description}</Typography>
                                <Typography sx={{ height: 50 }}>{currentService.points1}</Typography>
                                <Typography sx={{ height: 50 }}>{currentService.points2}</Typography>
                                <Typography sx={{ height: 50 }}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }} // Style for responsive image
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Page2;
