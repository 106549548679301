import React, { useState } from 'react';
import { Typography, Grid, Box, Button, Stack, TextField } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Emailbanner = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    const page = [
        { icon: <CheckCircleIcon />, title: 'Customized SEO Strategies' },
        { icon: <CheckCircleIcon />, title: 'Proven Track Record' },
        { icon: <CheckCircleIcon />, title: 'Data-Driven Approach' },
        { icon: <CheckCircleIcon />, title: 'Experienced Team of SEO Experts' },
    ];

    const [formValues, setFormValues] = useState({
        username: '',
        phone: '',
        services: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        alert("Submitted Successfully!");
    };

    return (
        <Grid
            container
            sx={{
                backgroundColor: '#f0f4f5',
                minHeight: '70vh',
                padding: isMobile ? '20px 10px' : '10px 30px',
                mt:1,
                mb:4
                
            }}
            spacing={isMobile ? 2 : 4}
            direction={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'flex-start'}
        >
            {/* Text Content Section */}
            <Grid item xs={12} md={8} >
                <Typography
                    textAlign={isMobile ? "center" : "left"}
                    variant='h3'
                    sx={{
                        fontSize: { xs: '1.8rem', md: '2rem' },
                        fontWeight: 600,
                        mb: 2,
                    }}
                >
                    SEO Services to Unlock the Potential of Your Brand
                </Typography>
                <Stack sx={{ color: "black", width: "100%", mb: 2 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            fontStyle: 'roboto',
                            textAlign: isMobile ? "center" : "left",
                            mb: 2,
                        }}
                    >
                        A well-optimized online presence is essential for any business hoping to connect with and engage a specific audience in today's fast-paced digital environment.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            fontStyle: 'roboto',
                            textAlign: isMobile ? "center" : "left"
                        }}
                    >
                        Our SEO services at GetMoreDigital are designed to boost your brand's visibility, generate organic traffic, and guarantee sustained success in search engine rankings.
                    </Typography>
                </Stack>

                {/* Icons List */}
                {page.map((page, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                        <CheckCircleIcon sx={{ fontSize: 30, color: '#a2b8a0', mr: 1 }} />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {page.title}
                        </Typography>
                    </Box>
                ))}
                {/* Buttons */}
                <Box sx={{ display: 'flex', gap: '20px', pt: 2 }}>
                    <Button variant="outlined" sx={{ backgroundColor: '#6c9ea8', color: '#fff', borderRadius: '10px' }}>
                        1k+ 5 star review
                    </Button>
                    <Button variant="outlined" sx={{ backgroundColor: '#6c9ea8', color: '#fff', borderRadius: '10px' }}>
                        1k+ Services Completed
                    </Button>
                </Box>
            </Grid>

            {/* Form Section */}
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'center' }}>
                <Box
                    component="form"
                    onSubmit={handleLoginSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        padding: '20px',
                        borderRadius: '20px',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'transparent',
                        maxWidth: '350px',
                        width: '100%',
                        height: '340px',
                    }}
                >
                    <TextField
                        label="Username"
                        variant="outlined"
                        name="username"
                        value={formValues.username}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                    />
                    <TextField
                        label="Contact No"
                        variant="outlined"
                        name="phoneNumber"
                        type="phoneNumber"
                        value={formValues.phone}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                    />
                    <TextField
                        label="Services"
                        variant="outlined"
                        name="services"
                        type="services"
                        value={formValues.services}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                backgroundColor: 'purple',
                                color: 'white',
                                borderRadius: '20px',
                                textTransform: 'none',
                                padding: '6px 16px',
                                fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#6a1b9a' },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Emailbanner;
