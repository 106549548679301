import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider } from '@mui/material';
import banner1 from '../../../../assets/content/content creation.jpg';
import banner2 from '../../../../assets/2.jpg';
import banner3 from '../../../../assets/3.jpg';
import banner4 from '../../../../assets/4.jpg';
import banner5 from '../../../../assets/5.jpg';
import banner6 from '../../../../assets/3.jpg'; // Ensure this path is correct

const services = [
    {
        value: 'Content Creation',
        title: 'Content Creation',
        description: 'Engaging content drives audience interaction, such as likes, comments, shares, and clicks. It encourages users to connect with your brand, fostering a sense of community and loyalty.',
        points1: '➢ SEO Optimization Techniques',
        points2: '➢ Creating Content for Different Platforms',
        points3: '➢ Mastering Storytelling',
        points4: '➢ Building a Content Creation Workflow',
        image: banner1,
    },
    {
        value: 'Audience Engagement',
        title: 'Audience Engagement',
        description: 'It is a measure of how effectively you are capturing your audience’s attention and encouraging them to interact with your content, products, or services.',
        points1: '➢ Foster Meaningful Interactions',
        points2: '➢ Improve Customer Retention',
        points3: '➢ Create Shareable Content',
        points4: '➢ Encourage Community Building',
        image: banner2,
    },
    {
        value: 'Advertising and Campaign ',
        title: 'Advertise & Campaign ',
        description: 'Advertising and campaigns are two integral parts of a marketing strategy that allow businesses to reach and engage with their target audience. ',
        points1: '➢ Audience Targeting and Segmentation',
        points2: '➢ Budgeting and Bidding Strategies',
        points3: '➢ A/B Testing for Campaign Optimization',
        points4: '➢ Campaign Timing and Scheduling',
        image: banner3,
    },
    {
        value: 'Performance Tracking',
        title: 'Performance Tracking',
        description: 'It involves collecting data from various touchpoints and platforms, then using this data to assess how well your activities are contributing to your business objectives. ',
        points1: '➢ Measure Campaign Success',
        points2: '➢ Adjust Campaigns in Real-Time',
        points3: '➢ Understand Customer Behavior',
        points4: '➢ Forecast Future Campaigns',
        image: banner4,
    },
    {
        value: ' Partnership Marketing',
        title: ' Partnership Marketing',
        description: 'The idea is to leverage each others strengths, resources, and customer bases to reach a broader audience, increase brand visibility, and drive sales.',
        points1: '➢ Structure and Negotiate Partnerships',
        points2: '➢ Measure the Success of a Partnership',
        points3: '➢ Maximize Customer Engagement',
        points4: '➢ Integrate Partner Brands Effectively',
        image: banner5,
    },
   
];

const Alldigitalservices = () => {
    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    return (
        <Container sx={{padding:'50px 10px'}}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
                    }}
                >
                    Engagement Strategies 
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '25px' }, color: "#3f4040", textAlign: 'center', mt: 1 }}
                >
                    A Complete Social Media Marketing Training for Modern Marketers
                </Typography>

                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: { xs: '10px', md: '10px 10px' }, justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }} // Responsive wrapping of tabs
                >
                    {services.map((service) => (
                        <Tab
                            key={service.value}
                            value={service.value}
                            label={service.title}
                            sx={{ fontSize: { xs: '12px', sm: '16px' }, fontFamily: 'sans-serif' }} // Responsive font size
                        />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '40px 100px' } }}>
                    <Grid item xs={12} md={6} mt={2}>
                        <Fade in>
                            <div>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4b87a6', height: 90 , fontSize:'40px'}}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height: {md:120, sm:80, xs:120} ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>{currentService.description}</Typography>
                                <Typography sx={{ height: {md:60, sm:50, xs:50}, fontSize: { xs: '15px', sm: '18px' , md:'18px'}   }}>{currentService.points1}</Typography>
                                <Typography sx={{ height: {md:60, sm:50, xs:50} ,fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>{currentService.points2}</Typography>
                                <Typography sx={{ height: {md:60, sm:50, xs:50} ,fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }} // Style for responsive image
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Alldigitalservices;
