import React, { useState } from 'react';
import { Typography, Button, Box, Grid, Container, Card, CardContent, CardMedia } from '@mui/material';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blogImage1 from '../../../assets/12.jpg';
import blogImage2 from '../../../assets/website design1.jpg';
import blogImage3 from '../../../assets/15.jpg';
import { useNavigate } from 'react-router-dom';

const blogPosts = [
    { 
        title: 'Key Strategies for Driving Organic Traffic',
        description: 'Discuss the importance of SEO in digital marketing, essential on-page and off-page techniques, and how to measure SEO success.',
        image: blogImage1,
        
    },
    {
        title: 'Beginner’s Guide to Google Ads (PPC): Maximizing ROI',
        description: 'Provide a step-by-step guide to setting up and optimizing Google Ads campaigns, focusing on effective strategies for a high return on investment.',
        image: blogImage2,
        
    },
    {
        title: 'Creating High-Value Content That Converts',
        description: 'Teach the basics of content marketing, how to create compelling blog posts, articles, and videos, and the role content plays in customer acquisition.',
        image: blogImage3,
        
    },
    {
        title: 'Growing Your Brand in the Digital Age',
        description: 'Explore social media strategies for businesses, how to choose the right platforms, and tips for increasing engagement and building a loyal following.',
        image: blogImage3,
        
    },
    {
        title: 'How to Build and Nurture Your Subscriber List',
        description: 'Explain the fundamentals of email marketing, including how to build a list, design email campaigns, and use automation tools effectively.',
        image: blogImage3,
        
    },
    { 
        title: 'Top Digital Marketing Trends to Watch in 2024',
        description: 'A forward-looking post on the latest trends shaping digital marketing, such as AI, voice search, and video marketing, and how businesses can stay ahead.',
        image: blogImage1,
        
    },
];

const Updates = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();
    
    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };
    
    const handleClick = () => {
        navigate('/allblogs');
    }

    return (
        <Box
            sx={{
                flexGrow: 2,
                backgroundColor: '#edeef2',
                padding: '10px',
                flexDirection: 'row',
                py:6
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ color: 'green', marginBottom: 1, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: 20, paddingTop: 10 }}>
                    Blogs & ARTICLES
                </Typography>
                <Typography variant="h2" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: { md: 40, xs: 25 } }}>
                    For Every Single <span style={{ color: 'green' }}>Update!</span>
                </Typography>

                <Grid container spacing={4}> {/* Grid container for layout */}
                    {blogPosts.map((post, index) => (
                        <Grid item xs={12} sm={4} key={index}> {/* Changed to xs={12} sm={6} for 2 cards in a row */}
                            <Card
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    marginTop: 3,
                                    backgroundColor: '#fff',
                                    marginBottom: -2,
                                    borderRadius: 2,
                                    boxShadow: 3, // Optional: Add shadow for a better look
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={post.image}
                                    alt={post.title}
                                />
                                <CardContent>
                                    <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 600 , height:90}}>
                                        {post.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 , height:80}}>
                                        {post.description}
                                    </Typography>
                                    {/* <Typography variant="caption" color="text.secondary">
                                        By {post.author}
                                    </Typography> */}
                                    <Button size="small" sx={{ float: 'right', color: '#684df4', mb:2 }} onClick={handleClick}>
                                        READ MORE →
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Updates;
