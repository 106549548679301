import React from 'react';
import { Box, Paper, ThemeProvider, Typography, Grid , useMediaQuery} from '@mui/material';
import { styled } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import business from '../../../assets/business owner.jpg'
import man1 from '../../../assets/man1-removebg-preview.png'
import student from '../../../assets/students-removebg-preview (1).png'
import seeker from '../../../assets/JOB SEEKER.jpg'

const theme = createTheme();


const steps = [
    {
        title: 'Business Owners',
        image: business,
    },
    {
        title: 'Experts in Marketing',
        image: man1,
    },
    {
        title: 'Students',
        image: student,
    },
    {
        title: 'Job Seekers',
        image: seeker,
    },
];

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'box-shadow 0.3s',
    boxShadow: theme.shadows[2],
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const Process = () => {

   
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, padding: '10px 20px', backgroundColor: '#fff', height: {xs:'100%', sm:'70vh', md:'50vh'}, mt:5 , mb:6}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginBottom: 3,
                        mt:-1
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#4b87a6',
                            textAlign: 'center',
                            mb: 3,
                            fontWeight: 600,
                            fontFamily: 'Arial, sans-serif', // Change to your preferred font
                            fontSize: { xs: '1.8rem', sm: '2.5rem' }, 
                          }}
                    >
                        Ideal Participants for This Program 
                    </Typography>
                </Box>

                <Grid container spacing={3} justifyContent="center">
                    {steps.map((steps, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Item sx={{ borderRadius: '10px', backgroundColor: '#fff' }}>
                                <Box>
                                    {/* Render the image */}
                                    <Box sx={{ marginBottom: 2 }}>
                                        <img src={steps.image} alt={steps.title} style={{ width: '100px', height: '100px' }} />
                                    </Box>
                                    <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, color: 'black' }}>
                                        {steps.title}
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default Process;
