import { Grid } from '@mui/material'
import React from 'react'
import Header1 from '../../../Page/Header1';
import Footer from '../../../Page/Footer'
import Page2 from '../ppc/Page2'
import Page1 from '../ppc/Page1'
import Page3 from './Page3';

const Ppcpage = () => {
  return (
    <Grid>
        <Header1/>
        <Page3/>
        <Page1/>
      <Page2/>
      <Footer/>
    </Grid>
  )
}

export default Ppcpage
