import React from 'react'
import Header1 from '../../Header1';
import Footer from '../../Footer'; 
import Cbannerc from './Cbannerc';
import Ccardingc from './Ccardingc';

const Allcontent = () => {
  return (
    <div>
      <Header1/>
      <Cbannerc/>
      <Ccardingc/>
      <Footer/>
    </div>
  )
}

export default Allcontent;
