import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import styled from 'styled-components';
import BadgeIcon from '@mui/icons-material/Badge';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const Courses = () => {


const StyledSpan = styled.span`
&::before {
  
}
text-decoration: underline;
color: '#907394';
`;

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Our Meta Ads courses are taught by industry professionals with years of hands-on experience in digital advertising. Learn directly from experts about the latest trends in Meta Ads and effective strategies for Facebook, Instagram, and other Meta platforms."
    },
    {
      icon: <BadgeIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Certification and Career Support",
      paragraph: "Earn prestigious certifications that enhance your credibility in the workplace. Our career support services, including resume writing, interview coaching, and job placement assistance, are designed to help you thrive and secure a successful career in Meta Ads and digital marketing."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Real-World, Hands-On Education",
      paragraph: "We focus on practical, real-world training rather than theoretical concepts. By working on live Meta Ads campaigns, optimizing ad performance, and learning through hands-on projects, you’ll gain the skills necessary to succeed in digital advertising."
    },
    {
      icon: <OfflinePinIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Skill Development Assurance",
      paragraph: "Our Meta Ads training offers courses for all levels, from beginners to advanced professionals. Tailor your learning experience to match your current expertise and career goals, ensuring continuous skill development throughout your journey."
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Updated Curriculum",
      paragraph: "To keep pace with the evolving world of Meta Ads and digital marketing, our course materials are regularly updated. You’ll stay ahead of the curve by learning the most current tools, methods, and strategies available in Meta Ads."
    },
    {
      icon: <LeaderboardIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Comprehensive Digital Marketing Focus",
      paragraph: "Our curriculum covers a broad range of digital marketing strategies, including Meta Ads, social media marketing, content planning, email marketing, PPC, and data analytics. You’ll gain a deep understanding of the entire digital ecosystem, beyond just Meta Ads."
    },
    {
      icon: <LocalLibraryIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Customized Learning Paths",
      paragraph: "We offer flexible, tiered courses designed for marketers at all levels. Whether you're just starting out or looking to advance your expertise, you can customize your learning path to align with your knowledge and career goals."
    },
    {
      icon: <MenuBookIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Flexible Learning Options",
      paragraph: "Our courses are available in a variety of formats to suit your schedule, including online and in-person options. This flexibility allows you to learn at your own pace while balancing your personal and professional life."
    },
    
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
      }}
    >
      <Container sx={{ minHeight: '80vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center">
          Why Meta Ads Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '40px',
          }}
        >
          Few Reasons to <StyledSpan>Choose Us </StyledSpan>for Meta Ads 
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6}>          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px',fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
