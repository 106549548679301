import React, { useState } from 'react';
import { Typography, Button, Box, Grid, Container, Card, CardContent, CardMedia, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogImage1 from '../../../../assets/12.jpg';
import blogImage2 from '../../../../assets/website design1.jpg';
import blogImage3 from '../../../../assets/15.jpg';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ReactTyped } from 'react-typed';

const blogPosts = [
    { 
        title: 'Manual Testing Fundamentals',
        duration: 'Duration : 3-6 months',
        description:'Covers the basics of software testing, testing methodologies, the software development lifecycle, and key testing concepts.',
        image: blogImage1,
        title1:'Rating',
        rating: 4.5, 
        reviewCount: 130,
    },
    {
        title: 'Automation Testing with Selenium',
        duration: 'Duration : 3-6 months',
        description:'A hands-on course for using Selenium to automate web application testing.',
        image: blogImage2,
        title1:'Rating',
        rating: 4.0,
        reviewCount: 120,
    },
    {
        title: 'Performance Testing with JMeter',
        duration: 'Duration : 3-6 months',
        description:'Focuses on testing the performance and scalability of applications using JMeter.',
        image: blogImage3,
        rating: 3.5,
        reviewCount: 130,
    },
    {
        title: 'API Testing with Postman and Rest Assured',
        duration: 'Duration : 3-6 months',
        description:'Teaches API testing fundamentals using Postman and scripting with Rest Assured (Java) for more advanced API tests.',
        image: blogImage3,
        rating: 4.2,
        reviewCount: 100,
    },
    {
        title: 'Mobile Application Testing',
        duration: 'Duration : 3-6 months',
        description:'Focuses on testing mobile applications across iOS and Android platforms.',
        image: blogImage3,
        rating: 4.8,
        reviewCount: 190,
    },
    { 
        title: 'Quality Assurance Automation with Cypress',
        duration: 'Duration : 3-6 months',
        description:'Covers end-to-end automation testing with Cypress, a popular JavaScript framework for modern web applications.',
        image: blogImage1,
        rating: 4.3,
        reviewCount: 230,
    },
];


const renderStars = (rating) => {
    // Set star size
    const starSize = { fontSize: '1rem' };

    return [...Array(5)].map((_, index) => {
        if (index < Math.floor(rating)) {
            return <StarIcon key={index} color='warning' style={starSize} />;
        }
        if (index === Math.floor(rating) && rating % 1 !== 0) {
            return <StarIcon key={index} color='warning' style={{ ...starSize, opacity: 0.5 }} />;
        }
        return <StarBorderIcon key={index} color='warning' style={starSize} />;
    });
};

const Updates = () => {
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate('/allblogs');
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const displayedPosts = showAll ? blogPosts : blogPosts.slice(0, 3);

    return (
        <Box
            sx={{
                flexGrow: 2,
                backgroundColor: '#edeef2',
                padding: '10px',
                flexDirection: 'row',
                py: 6,
                
            }}
        >
            <Container maxWidth="lg">
            <Typography variant="h4" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: { md: 40, xs: 25 } }}>
                   Our Top   <span style={{ color: 'purple' }}>Courses</span>
                </Typography>
            <Typography variant="h5" sx={{ mb: 4 }}>
        <ReactTyped
          strings={["For", "Fast-Track","Your Skills "]}
          typeSpeed={60}
          backSpeed={50}
          loop
          style={{ color: '#383838', fontWeight: 'bold', fontSize: '50px' }} 
        />
      </Typography>
                

                <Grid container spacing={4}> 
                    {displayedPosts.map((post, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Card
    sx={{
        marginTop: 3,
        backgroundColor: '#fff',
        borderRadius: 4.5,
        boxShadow: 3,
        height: '100%', // Ensures the card takes full height of its container
        display: 'flex', // Flexbox for alignment
        flexDirection: 'column', // Ensure content stacks vertically
    }}
>
    <CardMedia
        component="img"
        height="200"
        image={post.image}
        alt={post.title}
    />
    <CardContent sx={{ flexGrow: 1 }}> {/* FlexGrow to make content fill space */}
        <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 600, height: 100, mb: -4 }}>
            {post.title}
        </Typography>
        <Divider />
        <Grid container alignItems="center" mt={1}>
            {renderStars(post.rating)}
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {`(${post.reviewCount} reviews)`}
            </Typography>
        </Grid>
        <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2, marginBottom: -4, height: 80 }}>
            {post.duration}
        </Typography>
        <Typography variant="body1" color="black" sx={{ marginBottom: 2, height: '30%' }}>
            {post.description}
        </Typography>

        <Button
            variant="contained"
            sx={{
                borderRadius: '40px',
                background: 'linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)',
                color: 'white',
                marginTop: 1, // Ensures button sticks to the bottom
                ml: 10
            }}
            onClick={handleClick}
        >
            View Programs →
        </Button>
    </CardContent>
</Card>

                        </Grid>
                    ))}
                </Grid>

                {/* See All / See Less Button */}
                <Box sx={{ textAlign: 'center', marginTop: 6 }}>
                    <Button variant='contained' size='large' sx={{borderRadius: 4, backgroundColor:'linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)'}} onClick={toggleShowAll}>
                        {showAll ? 'See Less' : 'See All'}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default Updates;
