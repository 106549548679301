import React from 'react'
import Blog from './Blog'
import Header1 from '../Header1';
import Footer from '../Footer';
import Faqs from './Faqs';

const Allblogs = () => {
  return (
    <div>
      <Header1/>
      <Blog/>
      {/* <Faqs/> */}
      <Footer/>
    </div>
  )
}

export default Allblogs
