import React from 'react'
import Services1 from '../servicespage/Services1';
import Services2 from '../servicespage/Services2';
import Header1 from '../Header1';
import Footer from '../Footer'; 
import Servicebanner from '../servicespage/Servicebanner';
import Servicesdetails from '../servicespage/Servicesdetails';
import Servicesall from '../servicespage/Servicesall';
import Serviall from '../servicespage/Serviall';
import Servicestable from '../servicespage/Servicestable';
import Alldigitalservices from '../servicespage/Alldigitalservices';
// import Sfaqs from '../../Training/digitalmarketing/Sfaqs';

const Allservices = () => {
  return (
    <div>
      <Header1/>
      <Servicebanner/>
      <Servicesdetails/>
     
      {/* <Servicestable/> */}
      <Servicesall/>
      <Alldigitalservices/>
      <Footer/>
    </div>
  )
}

export default Allservices;
