import React, { useState } from 'react'; // Ensure useState is imported
import { Typography, Box, Button, useMediaQuery, useTheme, Grid, Autocomplete, TextField, InputLabel, MenuItem, Select, InputAdornment, IconButton } from '@mui/material';
import Slider from 'react-slick';
import Servicebanner2 from '../../../../assets/Servicebanner2.png';
import Servicebanner1 from "../../../../assets/Servicebanner1.png";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

// Define the data for each slide
const slidesData = [
  {
    title: "Enhance Tech Solutions",
    description: "Skills to build, manage, and enhance software and technology solutions.",
    image: Servicebanner1,
    buttonText: "Explore More",
  },
  {
    title: "Core Skills for Tech Success",
    description: "Essential coding, software development, and problem-solving skills to excel in the tech industry.",
    image: Servicebanner2,
    buttonText: "Discover More",
  }
];

// Custom arrow component
const Arrow = ({ className, style, onClick, direction }) => {
  return (
    
    <Box
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'white',
        borderRadius: '20%',
        cursor: 'pointer',
        zIndex: 1,
        width: '40px',
        height: '40px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-60%)',
        [direction === 'left' ? 'left' : 'right']: '40px',
      }}
      onClick={onClick}
    >
      {direction === 'left' ? <ArrowCircleLeftRoundedIcon /> : <ArrowCircleRightRoundedIcon />}
    </Box>
  );
};

// Settings for the carousel
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <Arrow direction="right" />,
  prevArrow: <Arrow direction="left" />,
};
const courseOptions = [
    { title: 'Front-End Development' },
    { title: 'Back-End Development' },
    { title: 'Full Stack Development' },
    { title: 'MERN Stack Development' },
    { title: 'Python Development' },
    { title: 'AI & ML Development' }
  ];

const Headerw = () => {
    const [search, setSearch] = useState('');
    
    // Function to handle the cancel (clear) action
    const handleClear = () => {
      setSearch(''); // Clear the search field
    };
  
    // Function to handle the search button click
    const handleSearchClick = () => {
      if (search) {
        // Navigate to different pages based on the selected course
        switch (search) {
          case 'Front-End Development':
            window.open('/frontend-development', '_blank'); // Opens in new tab
            break;
          case 'Back-End Development':
            window.open('/backend-development', '_blank');
            break;
          case 'Full Stack Development':
            window.open('/fullstack-development', '_blank');
            break;
          case 'MERN Stack Development':
            window.open('/mern-stack-development', '_blank');
            break;
          case 'Python Development':
            window.open('/python-development', '_blank');
            break;
          default:
            window.open('/', '_blank'); // Default action
        }
      } else {
        alert("Please select a course to search"); // Alert if no selection is made
      }
    };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect if it's a mobile view

  return (
    <Grid sx={{background: '#0618360a',}}>
    <Box
      sx={{
        padding: '20px',
        width: '100%',
        minHeight: '60vh',
        maxWidth: '1400px', // Set a max width for desktop
        margin: '0 auto', // Center the banner horizontally
      }}
    >
      <Slider {...sliderSettings}>
        {/* Map through the slide data */}
        {slidesData.map((slide, index) => (
          <Box key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? '20px' : '60px', // Adjust padding for mobile
              }}
            >
              {/* Text Section */}
              <Box sx={{ width: isMobile ? '100%' : '40%', textAlign: isMobile ? 'center' : 'left', padding: '10px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  {slide.title}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  {slide.description}
                </Typography>
                <Button variant="contained" sx={{ borderRadius: '40px', backgroundColor: "#6b84cc", color: 'white' }}>
                  {slide.buttonText}
                </Button>
              </Box>

              {/* Image Section */}
              <Box sx={{ width: isMobile ? '100%' : '40%', textAlign: isMobile ? 'center' : 'right', padding: '10px' }}>
                <img
                  src={slide.image}
                  alt={slide.title}
                  style={{ width: '100%', height: '300px', borderRadius: '60px' }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>

    <Grid container justifyContent='flex-end' px={3}>
        <Autocomplete
          freeSolo
          options={courseOptions.map((option) => option.title)}
          value={search}
          onChange={(event, newValue) => setSearch(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Search Courses"
              placeholder="Search Courses"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginRight: -5,  }}>
                    <IconButton
                      onClick={handleSearchClick}
                      sx={{
                        backgroundColor: '#1A237E',
                        color: 'white',
                        borderRadius: '0 20px 20px 0', 
                        padding: '10px',
                        '&:hover': {
                          backgroundColor: '#303F9F',
                        },
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: search && (
                  <InputAdornment position="start">
                    <IconButton onClick={handleClear}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingRight: 0,
                  borderRadius: '20px', // Adjusts the text field's border radius
                },
                '& .MuiAutocomplete-inputRoot': {
                  paddingLeft: '10px',
                },
                minWidth: 320,
              }}
            />

          )}
        />
      </Grid>
    </Grid>
  );
};

export default Headerw;
