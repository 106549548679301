import React from 'react';
import { Box, Paper, ThemeProvider, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SupportIcon from '@mui/icons-material/Support';
import VerifiedIcon from '@mui/icons-material/Verified';
import DomainIcon from '@mui/icons-material/Domain';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme();

const steps = [
    {
        title: 'Industry-Recognized Certification',
        description: 'We assist in identifying your needs and setting clear objectives.',
        icon: <WorkspacePremiumIcon sx={{ fontSize: 100 }} />,
    },
    {
        title: 'Weekly Evaluation',
        description: 'We conduct a thorough analysis and provide strategic planning.',
        icon: <AssignmentIcon sx={{ fontSize: 100 }} />,
    },
    {
        title: 'Placement Support',
        description: 'We develop detailed plans and execute them efficiently.',
        icon: <SupportIcon sx={{ fontSize: 100 }} />,
    },
    {
        title: '24/7 Assistance',
        description: 'We ensure the delivery of high-quality and timely results.',
        icon: <AccessTimeIcon sx={{ fontSize: 100 }} />,
    },
    {
        title: 'Training Certificate',
        description: 'We ensure the delivery of high-quality and timely results.',
        icon: <VerifiedIcon sx={{ fontSize: 100 }} />,
    },
    {
        title: 'Real-world Projects ',
        description: 'We ensure the delivery of high-quality and timely results.',
        icon: <DomainIcon sx={{ fontSize: 100 }} />,
    },
];

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'box-shadow 0.3s',
    boxShadow: theme.shadows[2],
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const Process = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 4,  // Show 1 slide on mobile, 4 on desktop
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, padding: '10px 10px', backgroundColor: '#f0f0f0', minHeight: {xs:'60vh', sm:'40vh', md:'55vh'}}}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                       
                        background: '#fff',
                        zIndex: -1,
                        borderRadius: '20px',
                    }}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginBottom: 2,
                        mt:5
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#4b87a6',
                            textAlign: 'center',
                            mb: 3,
                            fontWeight: 600,
                            fontFamily: 'Arial, sans-serif',
                            fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
                        }}
                    >
                        Core Benefits of Our Program
                    </Typography>
                </Box>

                <Slider {...settings}>
                    {steps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={2} key={index} padding='10px 20px'>
                            <Item sx={{ 
                                borderRadius: '10px',
                                
                                backgroundColor: '#fff', 
                                height: isMobile ? '100%' : '180px',  // Adjust height for mobile
                                padding: isMobile ? 1 : 3          // Adjust padding for mobile
                            }}>
                                <Box >
                                    <Box sx={{ marginBottom: 2 }}>{step.icon}</Box>
                                    <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, color: 'black' }}>
                                        {step.title}
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    ))}
                </Slider>
            </Box>
        </ThemeProvider>
    );
};

export default Process;
