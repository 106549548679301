import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider,useMediaQuery } from '@mui/material';
import banner1 from '../../../../assets/keyword.jpg';
import banner2 from '../../../../assets/seo/copy writing.jpg';
import banner3 from '../../../../assets/content/content creation.jpg';
import banner4 from '../../../../assets/seo/budget management.jpg';
import banner5 from '../../../../assets/seo/Landing page ops.jpg';
import banner6 from '../../../../assets/3.jpg'; // Ensure this path is correct

const services = [
    {
        value: 'Keyword Research ',
        title: 'Keyword Research ',
        description: 'Keyword research plays a crucial role in the success of Pay-Per-Click (PPC) advertising campaigns. It helps identify the most effective keywords to target, ensuring that your ads reach the right audience, at the right time, and with the right messaging.',
        points1: '➢ Understanding Search Intent for PPC Targeting',
        points2: '➢ Estimating Search Volume for Budget Allocation',
        points3: '➢ Assessing Keyword Competition for Bid Strategy',
        points4: '➢ Exploring Long-Tail Keywords for Cost Efficiency',
        image: banner1,
    },
    {
        value: 'Ad Copywriting ',
        title: 'Ad Copywriting ',
        description: 'Ad copy is the written content that appears in your PPC advertisements. It’s one of the most critical elements for driving clicks, conversions, and overall campaign performance.',
        points1: '➢ Understanding User Intent and Targeting',
        points2: '➢ Crafting Persuasive and Concise Messaging',
        points3: '➢ Incorporating Emotional Triggers and Urgency',
        points4: '➢ Experimenting with Different Copy Styles and Formats',
        image: banner2,
    },
    {
        value: 'Content Creation ',
        title: 'Content Creation ',
        description: 'Effective content creation not only engages users but also drives traffic, builds brand awareness, and boosts conversions .Through content creation, you’ll learn how to better understand your target audience’s interests, needs, and challenges. ',
        points1: '➢ Audience Research and Understanding',
        points2: '➢ Deliver tailored content using sophisticated segmentation.',
        points3: '➢ Storytelling and Brand Voice',
        points4: '➢ Performance Analytics',
        image: banner3,
    },
    {
        value: 'Budget Management',
        title: 'Budget Management',
        description: 'Budget management is the process of planning, allocating, and tracking financial resources to ensure that an organization’s goals are achieved efficiently and cost-effectively. ',
        points1: '➢ Financial Control and Oversight',
        points2: '➢ Resource Allocation and Prioritization',
        points3: '➢ Cost Efficiency and Waste Reduction',
        points4: '➢ Risk Management and Contingency Planning',
        image: banner4,
    },
    {
        value: ' Landing Page Optimization ',
        title: ' Landing Page Optimization  ',
        description: 'Optimizing landing pages ensures that visitors have a seamless, engaging experience, which improves the effectiveness of digital marketing campaigns.',
        points1: '➢ Increased Conversion Rates',
        points2: '➢ Enhanced User Experience (UX)',
        points3: '➢ Higher ROI on Advertising Spend',
        points4: '➢ A/B Testing and Data-Driven Decisions',
        image: banner5,
    },
   
];

const Alldigitalservices = () => {
    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    return (
        <Container sx={{ padding: '50px 10px' }}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
                    }}
                >
                    Exploring PPC Across Platforms </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '25px' }, color: "#3f4040", textAlign: 'center', mt: 1 }}
                >
                    Boost Your Brand with Expert Digital Marketing Solutions
                </Typography>

                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: { xs: '10px', md: '10px 10px' }, justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }} // Responsive wrapping of tabs
                >
                    {services.map((service) => (
                        <Tab
                            key={service.value}
                            value={service.value}
                            label={service.title}
                            sx={{ fontSize: { xs: '12px', sm: '16px' }, fontFamily: 'sans-serif' }} // Responsive font size
                        />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '40px 100px' },  }}>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <div>
                                <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#4b87a6', height: isMobile?180: 110 }}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height: isMobile?200: 160 ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>{currentService.description}</Typography>
                                <Typography sx={{ height: isMobile?60:60 ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>{currentService.points1}</Typography>
                                <Typography sx={{ height:isMobile?60: 60 ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>{currentService.points2}</Typography>
                                <Typography sx={{ height:isMobile?80: 60,fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }} // Style for responsive image
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Alldigitalservices;
