import { Grid } from '@mui/material'
import React from 'react'
import Headerw from './Headerw'
import Header1 from '../../Page/Header1'
import Footer from '../../Page/Footer'
import Development from './Development'
import Featured from './Featured'
import Company from './Company'

const Allweb = () => {
  return (
    <Grid>
        <Header1/>
      {/* <Headerw/> */}
      <Development/>
      <Featured/>
      {/* <Company/> */}
      <Footer/>
    </Grid>
  )
}

export default Allweb
