import { Grid } from '@mui/material'
import React from 'react'
import Header1 from '../../../Page/Header1';
import Footer from '../../../Page/Footer'
import Epage2 from '../email/Page2'
import Page1 from '../email/Page1'
import Page3 from './Page3';

const Seopage = () => {
  return (
    <Grid>
        <Header1/>
        <Page3/>
        <Page1/>
      <Epage2/>
      <Footer/>
    </Grid>
  )
}

export default Seopage
