import React from 'react';
import { Container, Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { AccessTime, Search, Visibility, Speed, Group } from '@mui/icons-material'; // Importing example icons
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import BadgeIcon from '@mui/icons-material/Badge';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';

const Courses = () => {

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('xs','sm', 'md'));

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Professionals from the industry with years of practical expertise teach our courses. Learn from professionals about the newest trends in SEO and digital marketing tactics."
    },
    {
      icon: <BadgeIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Certification and Guidance for Careers",
      paragraph: "Obtain reputable certificates to increase your credibility in the workplace. To help you thrive after training we also provide career support services including resume writing, interview coaching, and job placement aid."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Realistic, experiential education",
      paragraph: "We place more of an emphasis on real-world hands-on training than theoretical courses do. Working on actual projects, practicing live optimizations, and learning by doing will make sure you're prepared to use these abilities in the industry."
    },
    {
      icon: <OfflinePinIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Assurance of Skill Development ",
      paragraph: "We provide several tiers and specialized courses for marketers of all expertise levels. Adapt your educational experience to your level of knowledge and job aspirations. "
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Current Curriculum",
      paragraph: "To reflect the constantly evolving field of SEO and digital marketing, our course materials are updated on a regular basis. You'll keep ahead of the game by learning the newest methods and resources available in the field."
    },
    {
      icon: <LeaderboardIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Extensive Digital Marketing Range",
      paragraph: "Our curriculum gives you a best grasp of the digital marketing ecosystem by covering key pillars such as social media marketing, content planning, PPC advertising, email marketing, and data analytics. It goes beyond SEO."
    },
    {
      icon: <LocalLibraryIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Customized Educational Routes ",
      paragraph: "We provide several tiers and specialized courses for marketers of all expertise levels. Adapt your educational experience to your level of knowledge and job aspirations. "
    },
    {
      icon: <MenuBookIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Flexible Learning Choices ",
      paragraph: "We provide several tiers and specialized courses for marketers of all expertise levels. Adapt your educational experience to your level of knowledge and job aspirations. "
    },
    
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
     mt:3
      }}
    >
      <Container sx={{ minHeight: isTablet?'80vh': '60vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center" mt={3}>
          Why SEO Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            m:2,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '2.5rem',
          }}
        >
          Few Reasons to Student Select Us
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6} >          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px', fontSize: { xs: '15px', sm: '18px' , md:'18px'}  }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
