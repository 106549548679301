import React from 'react'
import Header1 from '../../Header1';
import Footer from '../../Footer'; 
import Aheader from './Aheader';


const Allafi = () => {
  return (
    <div>
      <Header1/>
      <Aheader/>
      <Footer/>
    </div>
  )
}

export default Allafi;
