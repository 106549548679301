import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import banner2 from '../../assets/bg1.jpg'; // Adjust the path if necessary

const StatsSection = () => {
  const [completedProjects, setCompletedProjects] = useState(10);
  const [satisfiedCustomers, setSatisfiedCustomers] = useState(10);
  const [expertEmployees, setExpertEmployees] = useState(10);
  const [awardsWon, setAwardsWon] = useState(10);

  useEffect(() => {
    if (completedProjects < 1000) {
      const interval = setInterval(() => {
        setCompletedProjects((prev) => Math.min(prev + 10, 1000));
      }, 30);
      return () => clearInterval(interval);
    }
  }, [completedProjects]);

  useEffect(() => {
    if (satisfiedCustomers < 600) {
      const interval = setInterval(() => {
        setSatisfiedCustomers((prev) => Math.min(prev + 10, 600));
      }, 30);
      return () => clearInterval(interval);
    }
  }, [satisfiedCustomers]);

  useEffect(() => {
    if (expertEmployees < 50) {
      const interval = setInterval(() => {
        setExpertEmployees((prev) => Math.min(prev + 1, 50));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [expertEmployees]);

  useEffect(() => {
    if (awardsWon < 20) {
      const interval = setInterval(() => {
        setAwardsWon((prev) => Math.min(prev + 1, 20));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [awardsWon]);

  const stats = [
    {
      icon: <CheckCircleOutlineIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#00BFFF' }} />,
      count: completedProjects,
      label: 'Completed Projects',
    },
    {
      icon: <GroupIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#32CD32' }} />,
      count: satisfiedCustomers,
      label: 'Satisfied Customers',
    },
    {
      icon: <BusinessCenterIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#FFA500' }} />,
      count: expertEmployees,
      label: 'Expert Employees',
    },
    {
      icon: <EmojiEventsIcon sx={{ fontSize: { xs: 30, md: 50 }, color: '#FFD700' }} />,
      count: awardsWon,
      label: 'Awards Won',
    },
  ];

  // Media Query
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1024px)');

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        backgroundColor: '#051836', 
        padding: { xs: '10px 0', md: '20px 0' },
        width: '100vw', 
        marginLeft: 0, 
        marginRight: 0, 
        minHeight:isTablet? '10vh' :'30vh', 
      }}
    >
      <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" sx={{
          mt: { xs: 4, sm: 6, md: 3 }, 
          mb: { xs: 4, sm: 6, md: 5 }, // Adjust the values for different screen sizes
        }}
      >
        {stats.map((stat, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={3} 
            sx={{
              textAlign: 'center', 
              padding: { xs: '5px 0', md: '10px 0' },
              // Add tablet-specific styles
              fontSize: isTablet ? '1.5rem' : 'initial', // Increase font size for tablet
              transform: isTablet ? 'scale(1.05)' : 'initial', // Slight scale effect for tablet
            }} 
            key={index}
          >
            {stat.icon}
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold', 
                color: '#fff', 
                fontSize: { xs: '1.5rem', md: '2rem' },
              }}
            >
              {stat.count}+
            </Typography>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: '#fff', 
                fontSize: { xs: '1rem', md: '1.5rem' },
              }}
            >
              {stat.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default StatsSection;
