import React, { useState } from 'react';
import { Typography, Button, Box, Grid, Container, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from '../../assets/newarticlesbackgroundimae.png';
import blogImage1 from '../../assets/12.jpg';
import blogImage2 from '../../assets/website design1.jpg';
import blogImage3 from '../../assets/15.jpg';
import { useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
 
const blogPosts = [
    { 
        title: 'Unlocking the Power of Data. ',
        description: 'Dive into the world of data-driven decision-making and discover how businesses can leverage data for growth and innovation.',
        image: blogImage1,
        author: 'Webteck',
    },
    {
        title: 'Mastering Digital Marketing Trends.  ',
        description: 'Explore the latest trends in digital marketing and learn strategies to enhance your online presence and engage your audience.',
        image: blogImage2,
        author: 'Webteck',
    },
    {
        title: 'The Future of AI in Business ',
        description: 'Discover how AI is transforming industries and shaping the future of business operations and customer experiences.',
        image: blogImage3,
        author: 'Webteck',
    },
];

const StyledSlider = styled(Slider)({
    '& .slick-prev, & .slick-next': {
        zIndex: 1,
        width: 30, 
        height: 30, 
        '&:before': {
            fontSize: 30, 
            color: 'black', 
        },
    },
    '& .slick-prev': {
        left: -18,
        top: 230, 
    },
    '& .slick-next': {
        right: -18,
        top: 230,
    },
});

const Updates = () => {
    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };
    const navigate=useNavigate();
    
    const handelClick =() => {
        navigate('/allblogs')
        window.scrollTo(0, 0);
    }
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                },
            },
        ],
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor: '#f9f9f9',
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                paddingBottom: 5,
                
            }}
        >
            
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ color: '#83bfc9', marginBottom: 1, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: 20, paddingTop: 10 }}>
                    NEWS & ARTICLES
                </Typography>
                <Typography variant="h2" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: {md:40,xs:25} }}>
                    Get Every Single  <span style={{ color: '#83bfc9' }}>Update!</span>
                </Typography>
                <Grid >
            
            
          </Grid>

                <StyledSlider {...settings}>
                    {blogPosts.map((post, index) => (
                        <Grid key={index} sx={{ padding: '0 10px' }}>
                            <Card
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    marginTop:2,
                                    marginBottom:2,
                                    width:{md:'90%'},
                                    marginLeft:{md:'15px'},
                                    borderRadius: 2,
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.06)',
                                        boxShadow: 6,
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={post.image}
                                    alt={post.title}
                                />
                                <CardContent>
                                    <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 600 }}>
                                        {post.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                                        {post.description}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        By {post.author}
                                    </Typography>
                                    <Button size="small" sx={{ float: 'right',  color: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)' }} onClick={handelClick}>
                                        READ MORE →
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </StyledSlider>
                {/* <Grid item xs={12} md={2} sx={{ flexDirection:'column', justifyContent: { xs: 'center', md: 'flex-end' } }}>
           
            <Button
  color="danger"
  disabled={false}
  loading={false}
  onClick={function(){}}
  size="lg"
  variant="soft"
  sx={{borderRadius: '20px', width:'50%', height:'10%'}}
>Save
  </Button>
            
          </Grid> */}
            </Container>
        </Box>
    );
};

export default Updates;
