import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { AccessTime, Search, Visibility, Speed, Group } from '@mui/icons-material'; // Importing example icons
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BadgeIcon from '@mui/icons-material/Badge';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';


const Courses = () => {

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Our social media marketing courses are led by industry professionals with years of hands-on experience in the field. Learn directly from experts about the latest trends and best practices in social media marketing, including campaign strategy, audience targeting, and content creation."
    },
    {
      icon: <BadgeIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Certification and Career Support",
      paragraph: "Earn industry-recognized certifications to boost your credibility in the workplace. Our career support services, including resume writing, interview coaching, and job placement assistance, are tailored to help you succeed in the dynamic field of social media marketing."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Real-World, Hands-On Education",
      paragraph: "We prioritize hands-on training over theory. By working on live campaigns, creating social media strategies, and analyzing metrics, you’ll gain practical skills to implement successful social media marketing strategies across platforms."
    },
    {
      icon: <OfflinePinIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Skill Development Assurance",
      paragraph: "Our courses cater to all skill levels, from beginners to advanced marketers. Tailor your learning experience to suit your expertise, helping you build confidence and advance your career in social media marketing."
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Updated Curriculum",
      paragraph: "Our course materials are consistently updated to reflect the fast-evolving world of social media marketing. Stay ahead by learning the latest tools, strategies, and platform updates to stay competitive in the industry."
    },
    {
      icon: <LeaderboardIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Comprehensive Digital Marketing Focus",
      paragraph: "Our curriculum covers key aspects of social media marketing, such as platform selection, content creation, audience engagement, analytics, and paid advertising. You’ll understand how social media fits into the broader digital marketing landscape."
    },
    {
      icon: <LocalLibraryIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Customized Learning Paths",
      paragraph: "We offer flexible course tiers that let you choose the best path for your skills and career goals. Whether you’re just starting or looking to refine your expertise, you can tailor your educational journey in social media marketing."
    },
    {
      icon: <MenuBookIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Flexible Learning Options",
      paragraph: "With both online and in-person options, you can adapt your learning to your schedule. Our flexible learning choices ensure you can learn at your own pace, balancing education with other responsibilities."
    },
    
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
      }}
    >
      <Container sx={{ minHeight: '80vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center">
          Why Social Media Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '2.5rem',
          }}
        >
          Few Reasons to Choose Us for Social Media
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6}>          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px' , fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
