import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider } from '@mui/material';
import banner1 from '../../../../assets/meta/Campaign objective.jpg';
import banner2 from '../../../../assets/meta/Target audience.jpg';
import banner3 from '../../../../assets/meta/ad creatives.jpg';
import banner4 from '../../../../assets/4.jpg';
import banner5 from '../../../../assets/meta/Analytics and ops.jpg';
import banner6 from '../../../../assets/3.jpg'; // Ensure this path is correct

const services = [
    {
        value: 'Campaign Objectives ',
        title: 'Campaign Objectives ',
        description: 'Create valuable, relevant content to attract and retain a clearly defined audience, driving profitable customer actions.',
        points1: '➢ Understanding Business Goals',
        points2: '➢ Performance Monitoring and Optimization',
        points3: '➢ Resource Management',
        points4: '➢ Target Audience Focus',
        image: banner1,
    },
    {
        value: 'Audience Targeting ',
        title: 'Audience Targeting  ',
        description: 'By understanding the demographics, interests, behaviors, and needs of your potential customers, you can create marketing campaigns that speak directly to them, making your efforts more effective and efficient. ',
        points1: '➢ Understanding Your Ideal Customer',
        points2: '➢ Personalization and Messaging',
        points3: '➢ Data Analysis and Insights',
        points4: '➢ Long-term Customer Relationships',
        image: banner2,
    },
    {
        value: 'Ad Creatives ',
        title: 'Ad Creatives ',
        description: 'Ad creatives refer to the visual and written components of an advertisement, which include images, videos, text, and other media used in online or offline advertising. ',
        points1: '➢ Understanding Your Audience’s Preferences',
        points2: '➢ The Role of A/B Testing',
        points3: '➢ How to Craft a Strong Call to Action (CTA)',
        points4: '➢ Emotional and Psychological Triggers',
        image: banner3,
    },
    {
        value: 'Budget Strategy',
        title: 'Budget  Strategy',
        description: 'It determines how much money to spend, where to spend it, and how to track and measure the effectiveness of your investments.',
        points1: '➢ Aligning Budget with Goals',
        points2: '➢ Effective Resource Management',
        points3: '➢ Budget Flexibility and Planning',
        points4: '➢ Identifying Key Metrics',
        image: banner4,
    },
    {
        value: ' Analytics and Optimization ',
        title: ' Analytics and Optimization  ',
        description: 'They allow businesses to track, measure, and improve their marketing efforts by using data to inform decisions, refine tactics, and ultimately enhance performance.',
        points1: '➢ Data-Driven Decision Making',
        points2: '➢ Improving Customer Experience',
        points3: '➢ Performance Metrics and KPIs',
        points4: '➢ Segmentation and Targeting',
        image: banner5,
    },

];

const Alldigitalservices = () => {
    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    return (
        <Container sx={{ padding: '50px 10px' }}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '40px' }, // Responsive font size
                    }}
                >
                    Advanced Meta Ads Strategies                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '20px' }, color: "#3f4040", textAlign: 'center', mt: 1 }}
                >
Handling Meta Ads- Vital Instruction for Modern Marketers                </Typography>

                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: { xs: '10px', md: '10px 10px' }, justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }} // Responsive wrapping of tabs
                >
                    {services.map((service) => (
                        <Tab
                            key={service.value}
                            value={service.value}
                            label={service.title}
                            sx={{ fontSize: { xs: '12px', sm: '16px' }, fontFamily: 'sans-serif' }} // Responsive font size
                        />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '40px 100px' } }}>
                    <Grid item xs={12} md={6} mt={3}>
                        <Fade in>
                            <div>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4b87a6', height: {md:90, xs:120}, fontSize: '40px' }}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height: {md:120, xs:200} , fontSize: '18px' }}>{currentService.description}</Typography>
                                <Typography sx={{ height: 60, fontSize: '18px'}}>{currentService.points1}</Typography>
                                <Typography sx={{ height: 60 ,fontSize: '18px'}}>{currentService.points2}</Typography>
                                <Typography sx={{ height: 60 ,fontSize: '18px'}}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }} // Style for responsive image
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Alldigitalservices;
