import React from 'react'
import Header1 from '../../Header1';
import Footer from '../../Footer'; 
import Banner from './Banner';
// import Card from './Carding';
import Carding from './Carding';

const Allservices = () => {
  return (
    <div>
      <Header1/>
      {/* <Banner/> */}
      <Carding/>
      <Footer/>
    </div>
  )
}

export default Allservices;
