import React from 'react'
import Media1 from '../clientpages/Media1'
import Header1 from '../Header1';
import Bannermedia from '../clientpages/bannermedia';
import Footer from '../Footer';
import Media2 from '../clientpages/Media2';

const Allclient = () => {
  return (
    <div>
      <Header1/>
      <Bannermedia/>
      <Media1/>
      <Media2/>
      <Footer/>
    </div>
  )
}

export default Allclient;
