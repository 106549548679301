import React from 'react';
import { Container, Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { AccessTime, Search, Visibility, Speed, Group } from '@mui/icons-material'; // Importing example icons
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import BadgeIcon from '@mui/icons-material/Badge';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';

const Courses = () => {

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Our PPC courses are taught by industry professionals with years of hands-on experience. Learn directly from experts about the latest trends and strategies in pay-per-click advertising, helping you stay up to date with the evolving digital landscape."
    },
    {
      icon: <BadgeIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Certification and Career Support",
      paragraph: "Earn recognized certifications that boost your professional credibility in PPC advertising. To help you succeed post-training, we offer career support services, including resume writing, interview coaching, and job placement assistance to ensure you're prepared for your next career step."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Real-World, Hands-On Education",
      paragraph: "We emphasize practical, experience-based learning over theoretical knowledge. By working on live PPC campaigns, optimizing ads, and analyzing performance, you’ll gain the real-world skills needed to succeed in the digital advertising industry."
    },
    {
      icon: <OfflinePinIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Skill Development Assurance",
      paragraph: "Our PPC courses cater to marketers at all levels, from beginners to advanced professionals. With specialized courses tailored to your expertise, you can adjust your learning experience to match your goals and career aspirations. "
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Updated Curriculum",
      paragraph: "Our course material is continuously updated to reflect the fast-changing world of PPC and digital advertising. You'll learn the latest methods, tools, and strategies to stay ahead in the field."
    },
    {
      icon: <LeaderboardIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Comprehensive PPC Focus",
      paragraph: "Our curriculum covers a wide range of PPC strategies, including Google Ads, Bing Ads, display advertising, and social media advertising. You’ll gain a well-rounded understanding of paid search and its role in a comprehensive digital marketing strategy."
    },
    {
      icon: <LocalLibraryIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Customized Learning Paths",
      paragraph: "We offer flexible learning options, allowing you to choose courses that match your knowledge and career goals. Whether you're just starting out or seeking advanced expertise, our tailored courses ensure you're on the right track."
    },
    {
      icon: <MenuBookIcon sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Flexible Learning Options",
      paragraph: "We provide various learning formats to suit different schedules and preferences. With both online and in-person options, you can adjust your learning path according to your availability, ensuring a balanced and flexible educational experience."
    },
    
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
     mt:3
      }}
    >
      <Container sx={{ minHeight: isTablet?'80vh': '60vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center" mt={3}>
          Why PPC Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            m:2,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '2.5rem',
          }}
        >
          Few Reasons to Student Select Us for PPC
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6}>          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px' ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
