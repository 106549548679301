import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Container, Typography, Grid, Divider, Card, CardContent } from '@mui/material';

const services = [
    {
        topic: 'Topic: 01',
        value: ' Creating An Online Presence',
        title: 'Creating An Online Presence',
        description: 'Building a strong unified online presence that connects with your audience increases brand awareness and stimulates engagement across digital platforms is the main goal of our training.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Establishing Brand Identity',
            '➢ Website Essentials',
            '➢ Social Media Strategy',
            '➢ SEO Basics',
            '➢ Content Creation',
            '➢ Analytics and Optimization',
        ],
    },
    {
        topic: 'Topic: 02',
        value: 'Social Media Marketing',
        title: 'Social Media Marketing',
        description: 'In-depth strategies and useful skills are taught in our Social Media Marketing training course to help you develop a strong online presence, interact with audiences, and produce quantifiable outcomes. This course will teach you how to use many networks including Facebook, Instagram, Twitter, LinkedIn, and more.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Develop Platform-Specific Strategies.',
            '➢ Audience Targeting and Segmentation',
            '➢ Content Creation and Planning',
            '➢ Social Media Advertising',
            '➢ Analytics and Performance Tracking',
            '➢ Community Management and Engagement',
        ],
    },

    {
        topic: 'Topic: 03',
        value: 'Search Engine Optimization',
        title: 'Search Engine Optimization',
        description: 'Our SEO courses have been meticulously created to give you the information and abilities required to improve your website exposure and search engine ranking.',
        title2: 'What You’ll Learn',
        points: [
            '➢ On-Page SEO',
            '➢ Content Marketing',
            '➢ Keyword Strategy',
            '➢ Technical SEO',
            '➢ Local SEO',
            '➢ Video Marketing',
           
        ],
    },
    {
        topic: 'Topic: 04',
        value: 'Content Marketing',
        title: 'Content Marketing',
        description: 'Our course on content marketing is designed to give you the fundamental knowledge and abilities you need to produce and monitor material that appeals to your target market and increases conversions and brand engagement.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Content Creation Techniques',
            '➢ Audience Analysis and Targeting',
            '➢ Content Distribution Strategies',
            '➢ Performance Metrics',
            '➢ SEO Optimization for Content',
        ],
    },
    {
        topic: 'Topic: 05',
        value: 'Pay-Per-Click',
        title: 'Pay-Per-Click',
        description: 'Our Pay-Per-Click (PPC) courses give you the knowledge and abilities to manage very successful paid advertising programs that increase brand awareness and return on investment. Examine the subtleties of PPC on well-known platforms and get advice on best practices from industry experts.',
        title2: 'What You’ll Learn',
        points: [
            '➢ PPC Fundamentals',
            '➢ Campaign Strategy & Planning',
            '➢ Ad Creation & Copywriting',
            '➢ Bidding & Budget Management',
            '➢ Performance Tracking & Analytics',
            '➢ Google Ads, Social Media, & More',
        ],
    },
    {
        topic: 'Topic: 06',
        value: 'Meta Ads ',
        title: 'Meta Ads',
        description: 'Discover how to utilize Meta Ads on the most popular social media platforms in the world to connect with and interact with your audience. The fundamentals of developing and improving Facebook and Instagram advertisements to raise brand awareness and improve conversions are covered in this course.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Comprehensive Ad Creation',
            '➢ Audience Targeting & Segmentation',
            '➢ Ad Bidding Strategies',
            '➢ Performance Tracking & Optimization',
            '➢ Remarketing Techniques',
        ],
    },
    {
        topic: 'Topic: 07',
        value: 'Email Marketing',
        title: 'Email Marketing',
        description: 'Giving you the skills you need to develop captivating and conversion-boosting email campaigns is the aim of our email marketing course. We offer practical guidance and tried-and-true techniques to help you maximize the effectiveness of your email marketing campaign.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Understanding Your Audience',
            '➢ Crafting Compelling Content',
            '➢ Design and Formatting',
            '➢ Email Automation',
            '➢ Analyzing Campaign Success',
        ],
    },
    {
        topic: 'Topic: 08',
        value: 'Video Marketing',
        title: 'Video Marketing',
        description: 'Our video marketing course aims to equip you with the skills necessary to create captivating video content that boosts engagement and brand loyalty.',
        title2: 'What You’ll Learn',
        points: [
            '➢ Creating High-Impact Videos',
            '➢ Storytelling Strategies',
            '➢ Optimizing for SEO',
            '➢ Leveraging Social Media',
            '➢ Analyzing Video Performance',
        ],
    },
];

const Cpage = () => {
    const [value, setValue] = React.useState(services[0].value);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);
    const currentIndex = services.findIndex(service => service.value === value);

    return (
        <Grid sx={{ padding: '20px 20px', backgroundColor: '#f5f7f5' }}>
            <Box padding='20px 30px'>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '20px' }, color: '#95959B', textAlign: 'left', mb: 1 }}
                >
                    Expert Training in Digital Marketing.
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'left',
                        // mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem', md: '40px' },
                    }}
                >
                    Program for Digital Marketing Courses
                </Typography>

                {/* <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black' }} /> */}

            </Box>

            <Grid container sx={{ width: '100%', justifyContent: 'center', mt: 3}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml:3,mr:4}}>
                    <IconButton
                        onClick={() => setValue(services[Math.max(currentIndex - 1, 0)].value)}
                        disabled={currentIndex === 0}
                        aria-label="previous tab"

                    >
                        <KeyboardArrowLeft />
                    </IconButton>


                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="service tabs"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap',
                            overflowX: 'auto',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': { display: 'none' },
                        }}
                    >

                        {services.map((service, index) => (
                            <Tab
                                
                                key={service.value}
                                value={service.value}
                                label={
                                    <Box sx={{ textAlign: 'center', gap: 3, width: '100%' }}>
                                        <Box sx={{ fontSize: { xs: '12px', sm: '16px', md: '14px', }, fontFamily: 'sans-serif', mb: 1, color: '#95959B' }}>
                                            {service.topic}
                                        </Box>

                                        <Box sx={{ 
        fontSize: { xs: '12px', sm: '16px', md: '16px' }, 
        fontFamily: 'sans-serif', 
        width: '100%', // Keep width at 100% for full width
        height: '20px', // Set a fixed height (adjust as needed)
        display: 'flex', // Use flex to center content
        alignItems: 'left', // Center content vertically
        justifyContent: 'left' // Center content horizontally
    }}>
                                            {service.value}
                                        </Box>
                                    </Box>
                                }
                                sx={{
                                    // width: 150,
                                    fontSize: { xs: '12px', sm: '16px' },
                                    fontFamily: 'sans-serif',
                                    border: value === service.value ? '2px solid purple' : '1px solid #e0e0e0',
                                    borderRadius: '5px',
                                    padding: '11px',
                                    backgroundColor: '#feffff',
                                    color: value === service.value ? 'red' : 'black',
                                    '&:hover': { backgroundColor: '#f5f5f5' },
                                    mr: index !== services.length - 1 ? 2 : 0 // Apply margin-right to all except the last tab
                                }}
                            />
                        ))}
                    </Tabs>



                    <IconButton
                        onClick={() => setValue(services[Math.min(currentIndex + 1, services.length - 1)].value)}
                        disabled={currentIndex === services.length - 1}
                        aria-label="next tab"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </Box>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '5px ' }, mt: 3, borderRadius: '10px' }}>
                    <Grid item xs={12} md={12} >
                        <Card
                            sx={{
                                // borderRadius: '50px',
                                padding: { xs: 2, sm: 4 },
                                boxShadow: 3,
                                backgroundColorwidth: '100%',
                                width: '90%', // Adjust this value to increase the card's maximum width
                                textAlign: 'justify',
                                margin: '0 auto' // Center the card horizontally
                            }}
                        >
                            <CardContent sx={{ textAlign: 'left' }}>
                                <Typography
                                    variant="h4"
                                    sx={{ fontWeight: 'bold', color: '#4b87a6', fontSize: { xs: '24px', md: '30px' }, height: 60 }}
                                >
                                    {currentService.title}
                                </Typography>

                                <Divider sx={{ mb: 2 }} />
                                <Typography sx={{ height: {md:50, sm: 90, xs:180}, fontSize:{ md:'16px', xs:'14px', sm:'15px'} }}>{currentService.description}</Typography>
                                <Typography sx={{ height: 50, fontWeight: 600, mt: 2, fontSize: '18px' }}>{currentService.title2}</Typography>
                                {currentService.points.map((point, index) => (
                                    <Typography sx={{ height: 50, fontSize: '16px' }} key={index}>{point}</Typography>
                                ))}
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Cpage;
