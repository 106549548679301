import React from 'react'
import Contact1 from '../contactus/Contact1';
import Header1 from '../Header1';
import Footer from '../Footer';
import Form from '../contactus/Form'

const Allabout = () => {
  return (
    <div>
    <Header1/>
    {/* <Contact1/>   */}
    <Form/>
    <Footer/>

    </div>
  )
}

export default Allabout