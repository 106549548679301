import React from 'react';
import { Typography, Grid, Box, Button, List, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import MailIcon from '@mui/icons-material/Mail';
import CampaignIcon from '@mui/icons-material/Campaign';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import InsightsIcon from '@mui/icons-material/Insights';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { ReactTyped } from 'react-typed';
import { useNavigate } from 'react-router-dom';

const IconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    borderRadius: '50%',
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
}));

const content = [
    {
        icon: <ContentPasteSearchIcon sx={{ fontSize: '2rem' }} />,
        title: 'Content Marketing',
        description: 'Create valuable, relevant content to attract and retain a clearly defined audience,  driving profitable customer actions.',
    }];
const ppc = [
    {
        icon: <CampaignIcon sx={{ fontSize: '2rem' }} />,
        title: 'Pay-Per-Click Advertising (PPC)',
        description: 'Drive targeted traffic and generate leads through paid ads on platforms like Google Ads and social media networks.',
    }];
const email = [
    {
        icon: <MailIcon sx={{ fontSize: '2rem' }} />,
        title: 'Email Marketing',
        description: 'Communicate directly with customers through personalized email campaigns to nurture leads and promote products or services.',
    }];
const affiliated = [
    {
        icon: <Diversity2Icon sx={{ fontSize: '2rem' }} />,
        title: 'Affiliate Marketing',
        description: ' Leverage partnerships with affiliates to drive sales through a performance-based model, expanding your reach and generating leads.',
    },
];
const influencer = [
    {
        icon: <InterpreterModeIcon sx={{ fontSize: '2rem' }} />,
        title: 'Influencer Marketing ',
        description: 'Collaborate with influencers to tap into their audience, enhance brand credibility, and drive engagement through authentic recommendations.',
    }];
const meta = [
    {
        icon: <AllInclusiveIcon sx={{ fontSize: '2rem' }} />,
        title: 'Meta Ads',
        description: 'Meta Ads empower brands to build awareness, drive conversions, and create meaningful customer connections.',
    }];
const cro = [
    {
        icon: <InsightsIcon sx={{ fontSize: '2rem' }} />,
        title: 'Conversion Rate Optimization (CRO)',
        description: 'Analyze user behavior to improve website design and content, ultimately increasing the percentage of visitors who convert into customers',
    }];
const video = [
    {
        icon: <VideoCameraFrontIcon sx={{ fontSize: '2rem' }} />,
        title: 'Video Marketing',
        description: 'Utilize engaging video content to capture audience attention, enhance storytelling, and boost conversion rates across platforms.',
    },
];
const Servicestable = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate();

    const handleOpen = () => {
        navigate('/allcontent')
    }
    const handleClick = () => {
        navigate('/allads')
    }
    const handleClose = () => {
        navigate('/allemail')
    }
    const handleLock = () => {
        navigate('/allafi')
    }
    const openClose = () => {
        navigate('/allinflu')
    }

    return (
        <Grid sx={{ backgroundColor: '#fcfcf7', padding: '40px', height: '100%' }}>
            <Grid container spacing={4}>
                {/* Left Grid Item */}
                <Grid item xs={12} md={6} sx={{mr: isMobile ? 2:'none', ml: isMobile ? -3:'none'}} >
                    <Box sx={{ padding: '20px', paddingRight: '1px' }}>
                        {/* <Typography
                            variant="h3"
                            sx={{
                                color: '#4b87a6',
                                fontWeight: 600,
                                marginBottom: '10px',
                                fontSize: '2rem',
                                textAlign: 'center',
                            }}
                        >
                            Trendy Services
                        </Typography> */}
                        <Typography variant="h5" sx={{ mb: 4 }}>
                            <ReactTyped
                                strings={["Smart Brand Solutions"]}
                                typeSpeed={60}
                                backSpeed={1}
                                loop
                                style={{ color: '#4b87a6', fontWeight: 'bold', fontSize: '2rem' }}
                            />
                        </Typography>

                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                fontSize: '3rem',
                                textAlign: 'center',
                                color: '#907394'
                            }}
                        >
                            Our Top Digital Services
                        </Typography>

                        {content.map((content, index) => (
                            <List key={index} >
                                <IconBox sx={{ width: '100%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {content.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {content.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {content.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: isMobile ? 50 : 80, mt: 3 }} onClick={handleOpen}>
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {ppc.map((ppc, index) => (
                            <List key={index} >
                                <IconBox sx={{ width: '100%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {ppc.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {ppc.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {ppc.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: isMobile ? 50 : 80, mt: 3 }} onClick={handleClick}>
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {email.map((email, index) => (
                            <List key={index} >
                                <IconBox sx={{ width: '100%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {email.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {email.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {email.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: isMobile ? 50 : 80, mt: 3 }} onClick={handleClose}>
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {affiliated.map((affiliated, index) => (
                            <List key={index} >
                                <IconBox sx={{ width: '100%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {affiliated.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {affiliated.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {affiliated.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: isMobile ? 50 : 80, mt: 3 }} onClick={handleLock}>
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}

                    </Box>
                </Grid>

                {/* Right Grid Item with Padding */}
                <Grid item xs={12} md={5} sx={{mr: isMobile ? 9:9}} >
                    <Box sx={{ mt: isMobile ? -4 : 35 }}>
                        {/* <ul style={{ listStyle: 'none', padding: 0 }}> */}
                        {influencer.map((influencer, index) => (
                            <List key={index}>
                                <IconBox sx={{ width: '120%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {influencer.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {influencer.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {influencer.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: 80, mt: 3 }} onClick={openClose}> 
                                        Know More
                                    </Button>
                                </IconBox>
                            </List>
                        ))}
                        {meta.map((meta, index) => (
                            <List key={index}>
                                <IconBox sx={{ width: '120%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {meta.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {meta.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {meta.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: 80, mt: 3 }} >
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {cro.map((cro, index) => (
                            <List key={index}>
                                <IconBox sx={{ width: '120%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {cro.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {cro.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {cro.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: 80, mt: 3 }} >
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {video.map((video, index) => (
                            <List key={index}>
                                <IconBox sx={{ width: '120%', height: '100px', display: 'flex', flexDirection: 'column', px: '1rem', py: 6, color: '#4b87a6' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        {video.icon}
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1rem', marginLeft: '10px', color: '#4b87a6' }}>
                                            {video.title}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: isMobile ? '0.875rem' : '1rem', // Smaller font on mobile
                                            marginTop: isMobile ? '5px' : '10px',
                                            m: isMobile ? 0.5 : 1, // Adjusted margin
                                            height: isMobile ? 'auto' : '100px', // Auto height for mobile to fit content
                                            color: 'black',
                                        }}
                                    >
                                        {video.description}
                                    </Typography>
                                    <Button variant='contained' sx={{ marginLeft: 1, alignSelf: 'center', borderRadius: '50px', backgroundColor: 'white', color: 'green', height: 80, mt: 3 }} >
                                        Know More
                                    </Button>
                                </IconBox>

                            </List>
                        ))}
                        {/* </ul> */}
                    </Box>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default Servicestable;
