import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Sample FAQ data array
const faqsData = [
  {
    question: "1. What is the format of the training?",
    answer: "To ensure a thorough learning experience. our training combines live classes, real-world projects, and evaluations."
  },
  {
    question: "2. How might my career benefit from GetMoreDigital training? ",
    answer: "Our training improves your employability and career chances in a fast expanding field by giving you the in-demand digital marketing skills you need. "
  },
  {
    question: "3. How long do the training courses last? ",
    answer: "The length of courses varies. for example, beginner programs usually last a few weeks, while advanced certifications take 3-6 months."
  },
  {
    question: "4. After I finish the training, what will I get?",
    answer: "Participants who successfully complete the program are awarded a GetMoreDigital accreditation, which is highly respected in the field of digital marketing."
  },
  {
    question: "5.  Is any prior experience required to participate in the training programs? ",
    answer: "No prior knowledge is required for our basic programs. However, people with some prior experience in digital marketing are best suited for our advanced courses."
  },
  // Add more FAQs here as needed
];



const Sfaqs = () => {

  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

  return (
    <Grid container sx={{
      backgroundColor: '#f7faf9',
      width: {md:'100%',xs:"100%"},

    }}>
      <Grid m={3}  sx={{width:{md:'100%',xs:"90%"}}}>
        <Typography variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            mt:5,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif', // Change to your preferred font
            fontSize: { xs: '1.7rem', sm: '2.5rem' }, // Adjust font size as needed
          }}>
          Frequently Asked Questions
        </Typography>

        <Grid container item xs={12} md={12} justifyContent={"center"}>
          {faqsData.map((faq, index) => (
            <Accordion key={index} sx={{ marginBottom: 2, backgroundColor: 'transparent', width: {md:900,xs:500} }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`} // Use unique aria-controls
                id={`panel${index}-header`} // Use unique id
              >
                <Typography variant="h6" sx={{ fontSize: '1.25rem' }}>{faq.question}</Typography> {/* Set a consistent font size */}
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Typography variant="body1" sx={{ fontSize: '1rem' }}>{faq.answer}</Typography> {/* Set a consistent font size */}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>

      </Grid>
    </Grid>
  );
};

export default Sfaqs;
