import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider, useMediaQuery } from '@mui/material';
import banner1 from '../../../../assets/keyword.jpg';
import banner2 from '../../../../assets/onpage.jpg';
import banner3 from '../../../../assets/content/Backlink stratergy.jpg';
import banner4 from '../../../../assets/technicalseo.jpg';
import banner5 from '../../../../assets/localseo.jpg';
import banner6 from '../../../../assets/wvedio.jpeg'; // Ensure this path is correct

const services = [
    {
        value: 'Keyword Research ',
        title: 'Keyword Research ',
        description: 'Discover the many kinds of keywords such as localized, long-tail, and short-tail keywords. Recognize their functions in directing appropriate traffic and focusing on particular audiences.',
        points1: '➢ Understanding Keyword Types ',
        points2: '➢ Tools and Techniques for Keyword Discovery',
        points3: '➢ Analyzing Keyword Metrics',
        points4: '➢ Integrating Keywords into Content Strategy',
        image: banner1,
    },
    {
        value: 'On-Page SEO ',
        title: 'On-Page SEO  ',
        description: 'To increase search engine visibility and user experience, learn how to optimize the HTML components, content, and structure of your website. ',
        points1: '➢ Keyword Optimization',
        points2: '➢ Content Quality and Structure',
        points3: '➢ Meta Tags and Descriptions',
        points4:'➢ Internal Linking and Site Navigation',
        image: banner2,
    },
    {
        value: 'Backlink Strategy ',
        title: 'Backlink Strategy',
        description: 'Unlock the potential of a successful keyword strategy to improve your online advertising. Discover how to use tools for competitive analysis and use on-page SEO best practices. ',
        points1: '➢ Understanding Backlinks',
        points2: '➢ Effective Link-Building Techniques',
        points3: '➢ Analyzing Competitors.',
        points4: '➢ Monitoring and Evaluation',
        image: banner3,
    },
    {
        value: 'Technical SEO',
        title: 'Technical SEO',
        description: 'Digital marketers, web developers, and business owners that want to improve the functionality and search engine visibility of their websites can enroll in our Technical SEO course. ',
        points1: '➢ Site Structure Optimization',
        points2: '➢ Performance Enhancement',
        points3: '➢ Indexing and Crawling Best Practices ',
        points4: '➢ Technical Audits and Tools',
        image: banner4,
    },
    {
        value: ' Local SEO ',
        title: ' Local SEO  ',
        description: 'Discover the fundamentals of local search optimization, such as setting up Google My Business, researching local keywords, and optimizing for visibility in local searches.',
        points1: '➢ Targeted Local Optimization',
        points2: '➢ Google My Business Mastery',
        points3: '➢ Local Link Building Strategies',
        points4: '➢ Reputation Management',
        image: banner5,
    },
    {
        value: 'Video Marketing',
        title: 'Video Marketing',
        description: 'Everything from the foundations of narrative to advanced production techniques is covered in our extensive video marketing courses. You will discover how to produce visually captivating content and use analytics to gauge your progress.',
        points1: '➢ Comprehensive Video Production Skills',
        points2: '➢ Effective Video Distribution Strategies',
        points3: '➢ Analytics and Performance Measurement',
        points4: '➢ SEO Optimization for Videos',
        image: banner6,
    },
];

const Alldigitalservices = () => {

    const isMobile = useMediaQuery('(max-width:600px)');
    // const isTablet = useMediaQuery((max-width:600px)');

    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    return (
        <Container sx={{padding:'50px 10px'}}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
                    }}
                >
                    Strategies for High Rankings
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '25px' }, color: "#3f4040", textAlign: 'center', mt: 1 }}
                >
A Complete SEO Training for Modern Marketers                </Typography>

                <Divider sx={{ mt: 1 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: '10px 10px', justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    variant={isMobile ? "scrollable" : "standard"}
                    scrollButtons={isMobile ? "auto" : "on"}
                >
                    {services.map((service) => (
                        <Tab key={service.value} value={service.value} label={service.title} sx={{ fontSize: '18px', fontFamily: 'sans-serif' }} />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: 2, justifyContent: 'center', padding: isMobile ? '20px' : '40px 100px' }}>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <div>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4b87a6', height: 80, mb: isMobile?'30px': 0}}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height: isMobile? 200 : 140 ,fontSize: '18px',}}>{currentService.description}</Typography>
                                <Typography sx={{ height:isMobile? 60: 60 ,fontSize: '18px', }}>{currentService.points1}</Typography>
                                <Typography sx={{ height: isMobile?60: 60 ,fontSize: '18px',}}>{currentService.points2}</Typography>
                                <Typography sx={{ height: isMobile?60 :60 ,fontSize: '18px', }}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Alldigitalservices;
