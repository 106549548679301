import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import Newbanner1 from '../../../assets/banner2.png';

const About1 = () => {
    return (
        <Grid
            sx={{
                backgroundImage: `url(${Newbanner1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '40vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // Centers content vertically and horizontally
                color: '#fff',
                textAlign: 'center',
            }}
        >
            <Box>
                <Typography
                    variant='h2'
                    sx={{
                        color: '#fff',
                        fontWeight: 400,
                        fontFamily: 'sans-serif',
                        paddingTop: '40px',
                    }}
                >
                    ABOUT US
                </Typography>
                {/* <Typography> Home </Typography>  */}
            </Box>
        </Grid>
    );
}

export default About1;
