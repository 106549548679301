import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { keyframes } from '@emotion/react';

const items = [
  { label: 'Website Marketing', icon: '🌐' },
  { label: 'SEO', icon: '🔍' },
  { label: 'Social Media Marketing', icon: '📣' },
  { label: 'PPC Marketing', icon: '💸' },
  { label: 'Content Marketing', icon: '📄' },
  { label: 'Email Marketing', icon: '✉' },
  { label: 'Affiliate Marketing', icon: '🤝' },
  { label: 'Video Marketing', icon: '🎥' },
];

// Define keyframes for clockwise rotation
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const Servicesdetails = () => {
  const [displayItems, setDisplayItems] = useState(items);

  const rotateItems = () => {
    setDisplayItems((prevItems) => {
      const newItems = [...prevItems];
      const lastItem = newItems.pop(); // Take the last item for rightward rotation
      newItems.unshift(lastItem); // Add the last item to the start
      return newItems;
    });
  };

  useEffect(() => {
    const interval = setInterval(rotateItems, 2000);
    return () => clearInterval(interval);
  }, []);

  const circleContainerStyle = {
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    height: '400px',
    margin: '50px auto',
  };

  const centerTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    width: '150px',
    textAlign: 'center',
  };

  const circleItemStyle = {
    position: 'absolute',
    width: '100px',
    height: '100px',
    backgroundColor: '#7b9a9d',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '14px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const rotatingCircleStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    animation: `${rotateAnimation} 10s linear infinite`, // Adjusted speed for smoother effect
  };

  const itemStyles = [
    { top: '1%', left: '50%', transform: 'translate(-50%, 0)' },
    { top: '10%', left: '80%', transform: 'translate(-50%, 0)' },
    { top: '50%', left: '90%', transform: 'translate(-50%, -50%)' },
    { top: '80%', left: '80%', transform: 'translate(-50%, -50%)' },
    { top: '90%', left: '50%', transform: 'translate(-50%, -50%)' },
    { top: '80%', left: '20%', transform: 'translate(-50%, -50%)' },
    { top: '50%', left: '10%', transform: 'translate(-50%, -50%)' },
    { top: '20%', left: '20%', transform: 'translate(-50%, -50%)' },
  ];

  return (
    <Grid container spacing={4} sx={{ backgroundColor: '#fff' }}>
      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <Box style={circleContainerStyle}>
          <Box style={centerTextStyle}>
            <Typography variant="h4" align="center" gutterBottom color='#b275cc'>
              Digital Marketing
            </Typography>
          </Box>

          {/* Circle items - Rotating */}
          <Box style={rotatingCircleStyle}>
            {displayItems.map((item, index) => (
              <Box key={index} style={{ ...circleItemStyle, ...itemStyles[index] }}>
                <Typography variant="h6" align="center">
                  {item.icon}
                </Typography>
                <Typography variant="body2" align="center" color='#fff'>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', textAlign: 'justify', padding: '30px', }}>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              marginBottom: '20px',
              fontSize: { xs: 30, sm: 30, md: 40 },
              textAlign: { xs: 'center', sm: 'center', md: 'start' },
              color: '#907394',
              ml: { xs: 3, sm: 5, md: 'none' },
            }}
          >
            Digital Success Tools
          </Typography>
          <Typography variant="body1" sx={{ ml: { xs: 4, sm: 5, md: 'none' } }}>
            We offer a wide range of digital marketing services tailored to meet your business needs. Whether you're looking to improve your online presence, boost your search engine rankings, or engage with your audience on social media, we have the tools and expertise to help you succeed.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '16px', ml: { xs: 4, sm: 5, md: 'none' } }}>
            Our services include SEO, PPC marketing, social media marketing, content creation, and much more. Let us help you craft a digital marketing strategy that drives results and maximizes your ROI.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Servicesdetails;