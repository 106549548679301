import { Grid, Typography } from '@mui/material'
import React from 'react'
import banner3 from '../../../assets/banner3.png'

const About1 = () => {
    return (
        <Grid sx={{
            backgroundImage: `url(${banner3})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '60vh',
        }}>
            <box sx={{ fontFamily: 'inherit', fontWeight: '400',  color: '#3d3c34', }}>
                <Typography variant='h3' style={{color:'#fff', textAlign:'center', paddingTop:'100px', fontFamily:'sans-serif',} }>Our Clent's and Media</Typography>
                <Typography variant='h5' style={{color:'white', textAlign:'center', padding:'30px', ml:'20px', mr:20, } }> Our love of creation motivates us to establish enduring relationships <br/>with companies by providing seamless, all-encompassing solutions<br/> that address each and every one of their requirements.
                     </Typography>
            </box>
        </Grid>
    )
}

export default About1
