import React from 'react'
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import banner2 from '../../../../assets/training/meta ads.jpg';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Page3 = () => {
  const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.8 });

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return (
    <Grid container sx={{ padding: '20px 50px', backgroundColor: '#fff' , mb:2}} >
      <Grid item sx={12} md={6} >
        <motion.div
          ref={cardOneRef}
          initial={{ opacity: 0, y: 50 }}
          animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: 0 }} // First card appears immediately
        >
          <img
            src={banner2}
            alt="banner2"
            style={{ width: isMobile ? '100%' : '90%', height: 'auto', borderRadius: '10px', }}
          />
        </motion.div>
      </Grid>

      <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', justifyContent: "center", textAlign: 'justify', mt:{xs:0, md:2, sm:0}  }}>
        <Typography variant="h2" sx={{ marginTop: '15px',textAlign: 'justify', fontSize: { xs: '20px', sm: '20px' , md:'30px',  fontWeight: 600,} ,color: '#4b87a6',}}> Build & Scale Effective Social Ad Campaigns</Typography>
      <Typography variant="body1" sx={{ marginTop: '15px',textAlign: 'justify', fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
    Meta Ads (formerly known as Facebook Ads) refer to the advertising platform offered by Meta (the parent company of Facebook, Instagram, Messenger, and WhatsApp). 


    </Typography>
    
    <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' , fontSize: { xs: '15px', sm: '18px' , md:'18px'}}}>
   
    Meta Ads allow businesses to create targeted advertisements across these platforms to reach specific audiences based on interests, demographics, behaviors, and more. 

    </Typography>
    
    <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify',fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
    By leveraging Meta’s advanced targeting capabilities, businesses can connect with users who are most likely to be interested in their products or services.
    </Typography>


      </Grid>

    </Grid>
  )
}

export default Page3
