import React from 'react';
import { Button, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { styled } from '@mui/system';
import aboutuspage1 from '../../../assets/aboutuspage1.png';
import newarticlebackgroundimae from '../../../assets/newarticlesbackgroundimae.png';

const Ripple = styled('span')(({ delay, isDesktop }) => ({
  position: 'absolute',
  borderRadius: '50%',
  width: isDesktop ? '100px' : '60px', 
  height: isDesktop ? '100px' : '60px', 
  backgroundColor: 'rgba(104, 77, 244, 0.2)',
  transform: 'translate(-50%, -50%)',
  animation: `heroripple 8s ${delay}s linear infinite`,
  '@keyframes heroripple': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(0)',
      opacity: 0.5,
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(4)',
      opacity: 0,
    },
  },
}));

const About3 = () => {
  const ripples = [0, 1, 2, 3];
  const isDesktop = useMediaQuery('(min-width:960px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:959px)');
  const isMobile = useMediaQuery('(max-width:599px)');

  return (
    <Box position="relative">
      <Grid container sx={{ py: 2, backgroundImage: `url(${newarticlebackgroundimae})`, }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: isDesktop ? 'flex-start' : 'center',
            justifyContent: 'center',
            marginTop: isDesktop ? -3 : 4,
            position: 'relative',
            padding: { xs: 2, md: 9 },
          }}
        >
          <Box
            sx={{
              maxWidth: '80%',
              textAlign: isDesktop ? 'left' : 'center',
              zIndex: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, color: '#4b87a6', marginBottom: 1, fontSize: { xs: 15, md: 20 } }}
            >
              Click. Convert. Grow
            </Typography>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '28px', md: '48px' },
                fontFamily: 'sans-serif',
                fontWeight: 600,
              }}
            >
              Your Digital Success Partner
            </Typography>
            <Typography variant="body1" color="textSecondary"  sx={{ marginTop: 3, }}>
            GetMoreDigital turns your idea into a reality by utilizing powerful business research and state-of-the-art digital marketing strategies.</Typography>
            {/* <Typography variant="body1" color="textSecondary"  sx={{ marginTop: 3, }}>          
            Our team specializes in developing unique marketing plans that raise sales promote engagement and expand your online presence. </Typography> */}
            <Typography variant="body1" color="textSecondary"  sx={{ marginTop: 3, }}> 
            From captivating ads to insights gleaned from data we are committed to enhancing your brand and producing quantifiable outcomes. Give us a chance to help you launch your digital success journey and realize your companys full potential.
            </Typography>
            <Box sx={{ my: 4 ,}}>
              
              <a href="https://www.youtube.com/@GetMyCollege_official" target="_blank" rel="noopener noreferrer">
                <Button
                  variant="outlined"
                  sx={{
                    color: '#4b87a6',
                    borderColor: '#4b87a6',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    borderRadius:'30px',
                    backgroundColor:'#ebebeb'
                  }} 
                >
                  <PlayCircleFilledWhiteIcon sx={{ color: '#4b87a6', mr: 1 }} />
                  Watch Our Story
                </Button>
              </a>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }}>
            {ripples.map((_, index) => (
              <Ripple
                key={index}
                delay={index * 1}
                isDesktop={isDesktop}
                sx={{
                  marginTop: isDesktop ? '130px' : isTablet ? '100px' : '50px',
                  marginLeft: isDesktop ? '-710px' : isTablet ? '-500px' : '100%',
                }}
              />
            ))}
            <img
              src={aboutuspage1}
              alt="aboutuspage1"
              style={{
                maxWidth: '100%',
                height: 'auto',
                width: 'auto',
                marginBottom: isDesktop ? 0 : 2,
                zIndex: 2,
                borderRadius:'50px',
                transform: 'scale(0.9)',
                marginLeft: isDesktop ? '-150px' : isTablet ? '-100px' : 0,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About3;
