import { Grid } from '@mui/material'
import React from 'react'
import Header1 from '../../../Page/Header1';
import Footer from '../../../Page/Footer';
import Page2 from '../socialmedia/Page2'
import Page1 from '../socialmedia/Page1'
import Page3 from '../socialmedia/Page3';

const Socialmediapages = () => {
  return (
    <Grid>
        <Header1/>
        <Page3/>
        <Page1/>
      <Page2/>
      <Footer/>
    </Grid>
  )
}

export default Socialmediapages
