import { Grid } from '@mui/material'
import React from 'react'
import Headerd from './Headerd'
import Header1 from '../../Page/Header1'
import Footer from '../../Page/Footer'
import Courses from './Courses'
import Features from './Features'
import Program from './Program'
import Sfaqs from './Sfaqs';

const Alldigital = () => {
  return (
    <Grid>
        <Header1/>
      {/* <Headerd/> */}
      <Courses/>
      <Features/>
      <Program/>
      <Sfaqs/>
      <Footer/>
    </Grid>
  )
}

export default Alldigital
