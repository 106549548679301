import React from 'react'
import About1 from '../aboutus/About1';
import Header1 from '../Header1';
import Footer from '../Footer';
import About2 from '../aboutus/About2';
import About3 from '../aboutus/About3';

const Allabout = () => {
  return (
    <div>
    <Header1/>
    <About1/>  
    <About3/>
    <About2/>
    <Footer/>
    </div>
  )
}

export default Allabout
