import React from 'react';
import { Grid, Typography, Button, Box, Card, CardContent, Avatar, Rating, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import ProfileImage from '../../assets/banner1.png';
import image1 from "../../assets/image1.png";
import banner3 from '../../assets/banner3.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

const testimonials = [
    {
        image: ProfileImage,
        name: 'Manoj',
        designation: 'Client',
        testimonial: 'GetMoreDigital transformed our online presence. Their tailored strategies significantly increased our engagement and sales.From SEO to social media, they cover every aspect with precision and creativity. The team is knowledgeable and responsive. Highly recommend!'
    },
    {
        image: ProfileImage,
        name: 'Sanjay',
        designation: 'Client',
        testimonial: 'Working with GetMoreDigital has been a game-changer for our business. Their innovative solutions and commitment to quality really set them apart. We are thrilled with the results and highly recommend them to anyone looking to elevate their digital presence!'
    },
    {
        image: ProfileImage,
        name: 'Shak Tech',
        designation: 'Client',
        testimonial: 'The training sessions at GetMoreDigital were incredibly insightful! The hands-on approach and real-world examples helped me grasp complex concepts easily. I feel much more confident in my digital marketing skills now!'
    },
    {
        image: ProfileImage,
        name: 'J.K Rawling',
        designation: 'Client',
        testimonial: 'GetMoreDigital training program exceeded my expectations. The instructors were highly knowledgeable and provided personalized guidance throughout. This experience has genuinely boosted my expertise and prepared me to achieve tangible results in my projects.'
    },
];


const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <ArrowCircleLeftRoundedIcon direction="left"  color='#004e6a'/>,
    nextArrow: <ArrowCircleRightRoundedIcon direction="right" color='#004e6a'/>
};



const Customer = () => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect small screens

    return (
        <Box
            style={{
                padding: "50px",
                height: { xs: "500px", md: "200px" },
                borderBottom: "1px solid #ccc",
                background: '#fafaf7',
                color:'black',
                ml:'10px',
                mr:'10px'
            }}
        >
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: { xs: "0", sm: "10px", md: "20px" },
                }}
            >
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            width: "100%",
                            fontWeight: "600",
                            fontSize: { xs: "20px", sm: "25px", md: "30px" },
                            lineHeight: "38px",
                            color: 'green',
                            textAlign: "center",
                        }}
                    >
                        Hear Our Happy Faces
                    </Typography>
                    <Typography
                        variant="p"
                        sx={{
                            width: "100%",
                            fontWeight: "500",
                            fontSize: { xs: "12px", sm: "12px", md: "16px" },
                            lineHeight: { xs: "25px", sm: "30px", md: "30px" },
                            color: "#95959B",
                            textAlign: "center",
                        }}
                    >
                        Smiles and Success Stories, Celebrating Satisfied Clients
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                    
                    <img src={image1} alt="banner2" style={{ width: '100%', height: '80%', borderRadius:'50px' }} />
                </Grid>

                {/* Right side - Testimonials Carousel */}
                <Grid item xs={12} md={7} marginTop={isMobile ? -2 : 8} marginLeft={isMobile ? -2 : 'none'} >

                    <Slider {...sliderSettings}>

                        {testimonials.map((testimonial, index) => (
                            <div key={index}>
                                {/* <Card sx={{ height: '280px', margin: '0 10px',marginTop:isMobile? "none":"30px", }}>
                                    <CardContent> */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 , marginLeft:4, fontSize:'100px', textAlign:"justify"}} >
                                <Typography variant="body1" sx={{ fontWeight: 'bold',}} gutterBottom>
                                    {testimonial.testimonial}
                                </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 , marginLeft:2}}>
                                    <Avatar alt={testimonial.name} src={testimonial.image} sx={{ marginRight: 2 }} />
                                    <Box>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                            {testimonial.name}
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>4.5</Typography>
                                            <Rating value={4.5} precision={0.5} readOnly sx={{ marginLeft: 1, }} />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* </CardContent>
                                </Card> */}
                            </div>
                        ))}
                    </Slider>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Customer;
