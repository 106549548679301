import React from 'react'
import Header1 from '../../Header1';
import Footer from '../../Footer'; 
import Addsbanner from './Addsbanner';


const Allads = () => {
  return (
    <div>
      <Header1/>
      <Addsbanner/>
      <Footer/>
    </div>
  )
}

export default Allads;
