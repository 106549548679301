import React from 'react'
import { Box, Grid, Stack, Typography, Divider, useTheme , useMediaQuery} from '@mui/material'
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('xs','sm', 'md'));
    const navigate = useNavigate();
  return (
    <Grid sx={{width:'100%', }} >
        <Box sx={{ backgroundColor: "#051836",  }}>
        <Grid
          container
          md={12}
          xs={12}
          sm={12}
          sx={{
            px: 5,
            alignItems: "center",
            width: "100%",
            justifyContent: "space-around",
            height: { xs: "auto", sm:'auto', md: "325px" },
            py: 5,
            "@media (min-width: 768px) and (max-width:1024px)": {
              padding: "40px",
            },
            "@media (min-width: 1024px) and (max-width:1440px)": {
              display: "flex",
              height: 305,
              py: 3,
            },
          }}
        >
            <Grid
            item
            md={3}
            xs={12}
            sm={8}
            sx={{
              height: "100%",
              px: { md: 2, xs: 0, sm: 2 },
              "@media (min-width: 1024px) and (max-width:1440px)": {
                px: 1,
              },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "left",
                py: 2,
                fontSize: "30px",
                fontWeight: "bold",
                fontFamily: "Itim",
                "@media (min-width: 1024px) and (max-width:1440px)": {
                  fontSize: "25px",
                },
              }}
            >
              GetMoreDigital
            <Stack sx={{ color: "#FFF", width: "100%", py: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: "25px",
                    fontSize: "17px",
                    textAlign: "justify",
                    "@media (min-width: 1024px) and (max-width:1440px)": {
                      fontSize: "16px",
                    },
                  }}
                >
                  Drive the Growth of Your Brand-Boost your online presence with GetMoreDigital.
                  Where data-driven tactics link your company's goals to achievement. 
                  Navigate the route to  marketing effect by joining us today.
                </Typography>
              </Stack>
            </Typography>
          </Grid> 
          <Grid
            item
            md={2}
            xs={6}
            sm={4}
            sx={{
              height: "100%",
              px: { md: 2, xs: 0, sm: 3 },
              "@media (min-width: 1024px) and (max-width:1440px)": {
                px: 1,
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFF",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
                "@media (min-width: 1024px) and (max-width:1440px)": {
                  fontSize: "17px",
                },
              }}
            >
              TOP SERVICES
              <Stack
                sx={{
                  color: "#FFF",
                  // textTransform: "uppercase",
                  py: 2,
                  gap: 1,
                }}
              >
                <Typography variant="body1">SEO</Typography>
                <Typography variant="body1"> Web Development</Typography>
                <Typography variant="body1">Google Ads</Typography>
                <Typography variant="body1">Social Media Marketing</Typography>
                <Typography variant="body1">Branding & Identity Marketing </Typography>
                {/* <Typography variant="body2">Influencer Marketing</Typography> */}
              </Stack>
            </Typography>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            sm={3}
            sx={{ height: "100%", px: { md: 2, xs: 0, sm: 2 } }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFF",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
                "@media (min-width: 1024px) and (max-width:1440px)": {
                  fontSize: "17px",
                },
              }}
            >
              TERMS & CONDITIONS
              <Stack
                sx={{
                  color: "#FFF",
                  // textTransform: "uppercase",
                  py: 2,
                  gap: 1,
                }}
              >
                <Typography variant="body1">Warranty Terms</Typography>
                <Typography variant="body1">Large Project Terms</Typography>
                <Typography variant="body1">Cancellation Policy</Typography>
                <Typography variant="body1">Confidentiality</Typography>
                <Typography variant="body1">Payment Terms</Typography>
                {/* <Typography variant="body2">Termination of Service</Typography> */}
              </Stack>
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sm={6}
            sx={{ height: "100%", px: { md: 2, xs: 0, sm: 2 } }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#FFF",
                fontWeight: "bold",
                textAlign: "left",
                py: 2,
                "@media (min-width: 1024px) and (max-width:1440px)": {
                  fontSize: "18px",
                },
              }}
            >
              Contact Us
              <Stack
                sx={{
                  color: "#FFF",
                  // textTransform: "uppercase",
                  py: 2,
                  gap: 1,
                }}
              >
                <Grid sx={{ display: "flex" }}>
                  <Grid>
                    <EmailIcon sx={{ mr: 2 }} />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        "@media (min-width: 1024px) and (max-width:1440px)": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "white", textDecoration: "none" }}
                    >
                      <a
                        href="mailto:info@getmycollege.com"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        info@getmoredigital.com
                      </a>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid sx={{ display: "flex" }}>
                  <Grid>
                    <PhoneIcon sx={{ mr: 2 }} />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1">Phone:</Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "white", textDecoration: "none" }}
                    >
                      <a
                        href="tel:+918867325152"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        +91 8867325152
                      </a>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid sx={{ display: "flex" }}>
                  <Grid>
                    <LocationOnIcon sx={{ mr: 2 }} />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1">Address:</Typography>
                    <Typography variant="body1">
                      GetMoreDigital, Basavanagudi Circle, Banglore,
                      Karnataka-560004
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Typography>
          </Grid>

            </Grid>

            <Divider style={{ backgroundColor: "#FFF" , mt:isTablet? 1: 3}} />
        <Grid
          container
          md={12}
          xs={12}
          sm={12}
          sx={{
            px: 5,
            py: 3,
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              px: { xs: 0, md: 2 },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#FFF",
                textAlign: { xs: "left", md: "left", sm: "left" },
              }}
            >
              Other Links
            </Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              px: { xs: 0, md: 2 },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: { md: "40px", xs: "11px" },
                color: "#FFF",
                py: { xs: 1, md: 0 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "15px", xs: "12px", sm: "13px" },
                  cursor: "pointer",
                }}
                onClick={() => navigate("/allabout")}
              >
                About GetMoreDigital
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "15px", xs: "12px", sm: "13px" },
                  cursor: "pointer",
                }}
                onClick={() => navigate("/allcontact")}
              >
                Contact Us
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "15px", xs: "12px", sm: "13px" },
                  cursor: "pointer",
                }}
                // onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "15px", xs: "12px", sm: "13px" },
                  cursor: "pointer",
                }}
                // // onClick={() => {
                // //   navigate("/terms-and-conditions");
                // }}
              >
                Terms & Conditions
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "15px", xs: "12px", sm: "13px" },
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   navigate("/careers");
                // }}
              >
                Careers
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", md: "center", sm: "center" },
              gap: { md: "12px", xs: "6px", sm: "12px" },
              py: { xs: 1, md: 0 },
              color: "#FFF",
              "@media (min-width: 1024px) and (max-width:1440px)": {
                gap: "8px",
              },
            }}
          >
            <LinkedInIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  " https://www.linkedin.com/company/getmycollege-com";
              }}
            />
            <FacebookIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.facebook.com/";
              }}
            />
            <InstagramIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  " https://www.instagram.com/getmycollege_/";
              }}
            />
            <TwitterIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://twitter.com/";
              }}
            />
            <YouTubeIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
            />
            <TelegramIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
            />
            <WhatsAppIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send?phone=7760001516";
              }}
            />
            <PinterestIcon
              sx={{
                borderRadius: "25px",
                height: "30px",
                width: "30px",
                color: "#FFF",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.pinterest.com/";
              }}
            />
          </Grid>
        </Grid>


      </Box>
      
    </Grid>
  )
}

export default Footer
