import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider , useMediaQuery} from '@mui/material';
import banner1 from '../../../../assets/content/Content strategy.jpg';
import banner2 from '../../../../assets/content/content creation.jpg';
import banner3 from '../../../../assets/content/content distribution.jpg';
import banner4 from '../../../../assets/content/story telling.jpg';
import banner5 from '../../../../assets/content/content promotion.jpg';
import banner6 from '../../../../assets/3.jpg'; // Ensure this path is correct

const services = [
    {
        value: 'Content Strategy ',
        title: 'Content Strategy ',
        description: 'It involves understanding the target audience, determining the types of content that resonate with them, when and where that content will be distributed to maximize its impact.',
        points: [
            '➢ Audience Research and Personas.',
            '➢ Create Engaging and Valuable Content',
            '➢ Distribute and Promote Content',
            '➢ Measure and Analyze Performance',
        ],
        image: banner1,
    },
    {
        value: 'Content Creation ',
        title: 'Content Creation ',
        description: 'The goal of content creation is to deliver value to the audience, build brand awareness, and foster relationships with customers or followers.',
        points: [
            '➢ Understand Your Audience',
            '➢ Develop Compelling Content',
            '➢ Utilize Content Distribution',
            '➢ Master the Creative Process',
        ],
        image: banner2,
    },
    {
        value: 'Content Distribution',
        title: 'Content Distribution',
        description: 'The goal is to get the content in front of the right people, at the right time, to drive engagement, increase visibility, and achieve specific business objectives.',
        points: [
            '➢ Identify the Right Channels',
            '➢ Tailor Content for Different Platforms',
            '➢ Leverage Social Media and Email Marketing',
            '➢ Repurpose and Cross-Promote Content',
        ],
        image: banner3,
    },
    {
        value: 'Storytelling',
        title: 'Storytelling',
        description: 'It involves crafting a story that resonates with an audience, often by using characters, events, conflicts, and resolutions to convey a deeper meaning or lesson.',
        points: [
            '➢ Craft Compelling Narratives',
            '➢ Connect Emotionally with Your Audience',
            '➢ Enhance Brand Messaging',
            '➢ Engage and Inspire Action',
        ],
        image: banner4,
    },
    {
        value: 'Content Promotion',
        title: 'Content Promotion',
        description: 'Promotion helps amplify the value of the content you create, ensuring it reaches the right people at the right time and increasing the visibility.',
        points: [
            '➢ Leverage Paid and Organic Methods',
            '➢ Measure and Analyze Effectiveness',
            '➢ Repurpose Content for Different Platforms',
            '➢ Collaborate with Influencers and Partners',
        ],
        image: banner5,
    },
];
const Cpage = () => {
    const [value, setValue] = React.useState(services[0].value);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    return (
        <Container sx={{ padding: '50px 10px' }}>
            <Box>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#4b87a6',
                        textAlign: 'center',
                        mb: 3,
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif',
                        fontSize: { xs: '1.8rem', sm: '2.5rem' },
                    }}
                >
                    Developing Powerful Storylines
                </Typography>
                <Typography
                    variant='h6'
                    sx={{ fontSize: { xs: '1.5rem', sm: '25px' }, color: '#3f4040', textAlign: 'center', mt: 1 }}
                >
                    Create engaging stories that capture and connect with your readers.
                </Typography>
                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: { xs: '10px', md: '10px 10px' }, justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
                >
                    {services.map((service) => (
                        <Tab
                            key={service.value}
                            value={service.value}
                            label={service.title}
                            sx={{ fontSize: { xs: '12px', sm: '16px' }, fontFamily: 'sans-serif' }}
                        />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: { xs: 2, sm: 2 }, justifyContent: 'center', padding: { xs: '20px', md: '40px 100px' } }}>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <div>
                                <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#4b87a6', height: isMobile?120:90 }}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height:isMobile?130: 100 }}>{currentService.description}</Typography>
                                {currentService.points.map((point, index) => (
                                    <Typography key={index} sx={{ height:  60 }}>{point}</Typography>
                                ))}
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Cpage;
