import React, { useState } from 'react';
import { Typography, Box, Grid, Container, Card, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import insta from '../../../assets/instagram.png';
import facebook from '../../../assets/facebook.png';
import twitter from '../../../assets/twitter.png';
import linkedin from '../../../assets/linkedin.png';
import youtube from '../../../assets/Youtube_logo.png';
import Bing from '../../../assets/Bing.png';
import Outbrain from '../../../assets/Outbrain.png';
import taboola from '../../../assets/taboola.png';
import mgid from '../../../assets/mgid.png';

const blogPosts = [
    { image: insta },
    { image: facebook },
    { image: twitter },
    { image: linkedin },
    // { image: youtube },
];
const mediaimage = [
    { image: Bing },
    { image: Outbrain },
    { image: taboola },
    { image: mgid },
    // { image: youtube },
];

const Updates = () => {
    const [hoveredCard, setHoveredCard] = useState(null);
    const navigate = useNavigate();

    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const handleClick = () => {
        navigate('/allblogs');
    };

    return (
        <Box
            sx={{
                flexGrow: 2,
                minHeight:'70vh',
                backgroundColor: '#edeef2',
                padding: '10px',
                paddingLeft:'5px',
                flexDirection: 'row',
                mt: '20',
                mb:'10',
            }}
        >
            <Container>
                <Typography
                    variant="h2"
                    sx={{
                        marginBottom: 5,
                        mt: 10,
                        textAlign: 'center',
                        fontFamily: 'sans-serif',
                        fontWeight: 600,
                        fontSize: { md: 40, xs: 25 },
                    }}
                >
                    Social Media <span style={{ color: 'green' }}>Platform!</span>
                </Typography>

                <Grid container spacing={2}>
                    {blogPosts.map((post, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    marginTop: 3,
                                    backgroundColor: '#fff',
                                    marginBottom: 4,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    px: { xs: 2, sm: 3, md: 4 },
                                    py: { xs: 2, sm: 3, md: 3 },
                                    width: { xs: '100px', sm: '140px', md: '160px' },
                                    height: { xs: '100px', sm: '140px', md: '160px' },
                                }}
                            >
                                <CardMedia component="img" image={post.image} alt={post.title} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container>
                <Typography
                    variant="h2"
                    sx={{
                        marginBottom: 5,
                        mt: 10,
                        textAlign: 'center',
                        fontFamily: 'sans-serif',
                        fontWeight: 600,
                        fontSize: { md: 40, xs: 25 },
                    }}
                >
                    Native Platforms
                </Typography>

                <Grid container spacing={2}>
                    {mediaimage.map((post, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    marginTop: 3,
                                    backgroundColor: '#fff',
                                    marginBottom: 4,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    px: { xs: 2, sm: 3, md: 4 },
                                    py: { xs: 2, sm: 3, md: 3 },
                                    width: { xs: '100px', sm: '140px', md: '160px' },
                                    height: { xs: '100px', sm: '140px', md: '100px' },
                                }}
                            >
                                <CardMedia component="img" image={post.image} alt={post.title} />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Updates;
