import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Container, Typography, Grid, Fade, Divider, useMediaQuery } from '@mui/material';
import banner1 from '../../../assets/wcontent.jpeg'; // Update with your actual image path
import banner2 from '../../../assets/wpay.jpeg';
import banner3 from '../../../assets/wemail.jpeg';
import banner4 from '../../../assets/wmeta.jpeg';
import banner5 from '../../../assets/winfluencer.jpeg';
import banner6 from '../../../assets/wvedio.jpeg';

const services = [
    {
        value: 'Content Marketing',
        title: 'Content Marketing',
        description: 'Create valuable, relevant content to attract and retain a clearly defined audience, driving profitable customer actions.',
        points1: ' ➢ Superior, educational material establishes your brand as a reliable authority, fostering confidence and enduring client connections.',
        points2: ' ➢ By motivating readers to take action, strategic content raises conversion rates and returns on investment for your website.',
        points3: ' ➢ Publishing trends and insights on a regular basis distinguishes your brand from rivals and establishes it as a thought leader.',
        points4: ' ➢ We can guide potential consumers through the buyer’s journey by attracting and nurturing them with well-crafted content.',
        image: banner1,
    },
    {
        value: 'PPC',
        title: 'PPC',
        description: 'Drive targeted traffic and generate leads through paid ads on platforms like Google Ads and social media networks.',
        points1: ' ➢ For a better return on investment, we find high-intent keywords that complement your target market and optimize ad relevancy.',
        points2: ' ➢ Thorough control over targeting, bidding tactics, ad budget, and ad placements on social media, Google, and Bing.',
        points3: ' ➢ Our team continuously optimizes for performance and relevancy while creating captivating ad language and captivating images. Use comprehensive analytics to track campaign performance.',
        image: banner2,
    },
    {
        value: 'Email Marketing',
        title: 'Email Marketing',
        description: 'Communicate directly with customers through personalized email campaigns to nurture leads and promote products or services.',
        points1: ' ➢ To ensure high engagement and conversion, create customized email campaigns that complement your audience’s tastes and brand goals.',
        points2: ' ➢ Deliver tailored content using sophisticated segmentation to increase relevance and open rates for every subscriber group.',
        points3: ' ➢ Re-engagement campaigns can be used to target dormant subscribers and keep an active, healthy email list.',
        image: banner3,
    },
    {
        value: 'Meta Ads',
        title: 'Meta Ads',
        description: 'Meta Ads empower brands to build awareness, drive conversions, and create meaningful customer connections.',
        points1: ' ➢ To reach the proper customers, make use of Meta’s sophisticated targeting options, which include demographics, interests, behavior, and lookalike audiences.',
        points2: ' ➢ Create, track, and refine campaigns on Facebook and Instagram to guarantee economical outcomes and continuous performance enhancement.',
        points3: ' ➢ To get the most out of your advertising budget, strategically allocate funds where they will yield the greatest return.',
        image: banner4,
    },
    {
        value: 'Influencer Marketing',
        title: 'Influencer Marketing',
        description: 'Collaborate with influencers to tap into their audience, enhance brand credibility, and drive engagement through authentic recommendations.',
        points1: ' ➢ In order to optimize relevance and engagement, we find and work with influencers who share the same values as your business and target market.',
        points2: ' ➢ To create genuine, eye-catching content that connects with their fans and spreads the word about your company, we collaborate directly with influencers.',
        points3: ' ➢ By cultivating enduring relationships with influencers who sincerely support your brand, we increase the impact and durability of brand loyalty.',
        image: banner5,
    },
    {
        value: 'Video Marketing',
        title: 'Video Marketing',
        description: 'Utilize engaging video content to capture audience attention, enhance storytelling, and boost conversion rates across platforms.',
        points1: ' ➢ Make captivating visual storyboards and texts that enthrall audiences and successfully convey the message of your company.',
        points2: ' ➢ To increase interaction and shareability, customize short-form videos for social media sites like Facebook, Instagram, and YouTube.',
        points3: ' ➢ Plan webinars, Q&A sessions, and live events to interact with audiences in real time and build brand experiences.',
        image: banner6,
    },
];

const Alldigitalservices = () => {
    const [value, setValue] = React.useState(services[0].value);
    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const currentService = services.find(service => service.value === value);

    return (
        <Container>
            <Box>
                <Typography variant='h3' sx={{ fontSize: '40px', color: "#907394", fontWeight: 600, textAlign: 'center' }}>
                    Smart Digital Strategies
                </Typography>
                <Typography variant='h5' sx={{ fontSize: '20px', color: "black", textAlign: 'center', mt: 1 }}>
                    Boost Your Brand with Expert Digital Marketing Solutions
                </Typography>
                <Divider sx={{ mt: 2 }} />
            </Box>
            <Grid container sx={{ width: '100%', padding: '10px 10px', justifyContent: 'center', mt: 3 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    variant={isMobile ? "scrollable" : "standard"}
                    scrollButtons={isMobile ? "auto" : "on"}
                >
                    {services.map((service) => (
                        <Tab key={service.value} value={service.value} label={service.title} sx={{ fontSize: '18px', fontFamily: 'sans-serif' }} />
                    ))}
                </Tabs>

                {/* Tab Content */}
                <Grid container sx={{ padding: 2, justifyContent: 'center', padding: isMobile ? '20px' : '40px 100px', mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <div>
                                <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#4b87a6', height: 90, mb: isMobile?'30px': 0}}>{currentService.title}</Typography>
                                <Divider sx={{ mb: 2, borderBottomWidth: 2, color: 'black', mr: 2 }} />
                                <Typography sx={{ height:{xs:100, md: 80, sm: 80} }}>{currentService.description}</Typography>
                                <Typography sx={{ height:{xs:100, md: 80, sm: 80} }}>{currentService.points1}</Typography>
                                <Typography sx={{ height:{xs:100, md: 80, sm: 80} }}>{currentService.points2}</Typography>
                                <Typography sx={{ height:{xs:100, md: 80, sm: 80} }}>{currentService.points3}</Typography>
                            </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Fade in>
                            <img
                                src={currentService.image}
                                alt={currentService.title}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Alldigitalservices;
