import React, { useState } from 'react';
import { Typography, Button, Box, Grid, Container, Card, CardContent, CardMedia, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogImage1 from '../../../assets/12.jpg';
import blogImage2 from '../../../assets/website design1.jpg';
import blogImage3 from '../../../assets/15.jpg';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const blogPosts = [
    { 
        title: 'Full-Stack Development',
        duration: 'Duration : 3-6 months',
        description:'Master both front-end and back-end technologies to build dynamic, responsive web applications from start to finish.',
        image: blogImage1,
        title1:'Rating',
        rating: 4.5, 
        reviewCount: 130,
    },
    {
        title: 'AI & ML Development',
        duration: 'Duration : 3-6 months',
        description:'Explore the world of Artificial Intelligence and Machine Learning, mastering techniques to analyze data and build intelligent systems that learn and adapt.',
        image: blogImage2,
        title1:'Rating',
        rating: 4.0,
        reviewCount: 120,
    },
    {
        title: 'Front-End Development',
        duration: 'Duration : 3-6 months',
        description:'Master the art of building visually engaging and responsive websites with Front-End Development.',
        image: blogImage3,
        rating: 3.5,
        reviewCount: 130,
    },
    {
        title: 'Back-End Development',
        duration: 'Duration : 3-6 months',
        description:'Master the essentials of server-side logic, databases, and APIs to build robust and scalable backend systems.',
        image: blogImage3,
        rating: 4.2,
        reviewCount: 100,
    },
    {
        title: 'MERN Stack Development',
        duration: 'Duration : 3-6 months',
        description:'Full-stack web development with MongoDB, Express, React, and Node.js to build dynamic web application',
        image: blogImage3,
        rating: 4.8,
        reviewCount: 190,
    },
    { 
        title: 'Python Development',
        duration: 'Duration : 3-6 months',
        description:'Python development for building versatile applications, from web to data science, in just 3-6 months.',
        image: blogImage1,
        rating: 4.3,
        reviewCount: 230,
    },
];


const renderStars = (rating) => {
    // Set star size
    const starSize = { fontSize: '1rem' };

    return [...Array(5)].map((_, index) => {
        if (index < Math.floor(rating)) {
            return <StarIcon key={index} color='warning' style={starSize} />;
        }
        if (index === Math.floor(rating) && rating % 1 !== 0) {
            return <StarIcon key={index} color='warning' style={{ ...starSize, opacity: 0.5 }} />;
        }
        return <StarBorderIcon key={index} color='warning' style={starSize} />;
    });
};

const Updates = () => {
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate('/allblogs');
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const displayedPosts = showAll ? blogPosts : blogPosts.slice(0, 3);

    return (
        <Box
            sx={{
                flexGrow: 2,
                backgroundColor: '#edeef2',
                padding: '10px',
                flexDirection: 'row',
                py: 6,
                
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="h4" sx={{ color: '#4b87a6', marginBottom: 1, marginTop:-6, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, paddingTop: 10 }}>
                    Top Courses 
                </Typography>
                <Typography variant="h2" sx={{ marginBottom: 5, textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 600, fontSize: { md: 40, xs: 25 } }}>
                    For Fast-Track   <span style={{ color: '#4b87a6' }}>Your Skills </span>
                </Typography>

                <Grid container spacing={4}> 
                    {displayedPosts.map((post, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Card
    sx={{
        marginTop: 3,
        backgroundColor: 'transparent',
        borderRadius: 4.5,
        boxShadow: 3,
        height: '100%', // Ensures the card takes full height of its container
        display: 'flex', // Flexbox for alignment
        flexDirection: 'column', // Ensure content stacks vertically
    }}
>
    <CardMedia
        component="img"
        height="200"
        image={post.image}
        alt={post.title}
    />
    <CardContent sx={{ flexGrow: 1 }}> {/* FlexGrow to make content fill space */}
        <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 600, height: 80, mb: -4 }}>
            {post.title}
        </Typography>
        <Divider />
        <Grid container alignItems="center" mt={1}>
            {renderStars(post.rating)}
            <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {`(${post.reviewCount} reviews)`}
            </Typography>
        </Grid>
        <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2, marginBottom: -4, height: 80 }}>
            {post.duration}
        </Typography>
        <Typography variant="body1" color="black" sx={{ marginBottom: 2, height: '30%' }}>
            {post.description}
        </Typography>

        <Button
            variant="contained"
            sx={{
                borderRadius: '40px',
                background: 'linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)',
                color: 'white',
                marginTop: 1, // Ensures button sticks to the bottom
                ml: 10
            }}
            onClick={handleClick}
        >
            View Programs →
        </Button>
    </CardContent>
</Card>

                        </Grid>
                    ))}
                </Grid>

                {/* See All / See Less Button */}
                {/* <Box sx={{ textAlign: 'center', marginTop: 6 }}>
                    <Button variant='contained' size='large' sx={{borderRadius: 4, backgroundColor:'linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)'}} onClick={toggleShowAll}>
                        {showAll ? 'See Less' : 'See All'}
                    </Button>
                </Box> */}
            </Container>
        </Box>
    );
};

export default Updates;
