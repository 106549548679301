import React from 'react'
import { Grid, Typography,useTheme, useMediaQuery } from '@mui/material'
import banner2 from '../../../../assets/training/ppc.jpg';
import { motion } from 'framer-motion'; 
import { useInView } from 'react-intersection-observer';

const Page3 = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.8 });
  return (
    <Grid container sx={{padding:'20px 50px',backgroundColor:'#fff' }} >
    <Grid item sx={12} md={6} >
    <motion.div
                          ref={cardOneRef}
                          initial={{ opacity: 0, y: 50 }}
                          animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
                          transition={{ duration: 1, delay: 0 }} // First card appears immediately
                      >
          <img
            src={banner2}
            alt="banner2"
            style={{ width: isMobile? '100%':'90%', height: 'auto', borderRadius: '10px' ,}}
          />
          </motion.div>
      </Grid>
      
    <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify', }}>
    <Typography variant="h2" sx={{
                          color: '#4b87a6',
                          textAlign: 'left',
                          mt:2,
                          mb: 3,
                          fontWeight: 600,
                          fontFamily: 'Arial, sans-serif',
                          fontSize: { xs: '1.8rem', sm: '2rem' , md:'40px' }, // Responsive font size
                      }}>
                             Boost Traffic & Maximize ROI
                          </Typography>
                          <Typography variant="body1" sx={{ marginTop: '15px',textAlign: 'justify', fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
    PPC (Pay-Per-Click) is a form of online advertising where advertisers pay a fee each time their ad is clicked. 
    It’s a way of buying visits to your site rather than attempting to earn them organically through SEO. 

    </Typography>
    
    <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' , fontSize: { xs: '15px', sm: '18px' , md:'18px'}}}>
   
    The primary objective is to ensure that the cost of each click is outweighed by the value of the conversions it generates, making it an efficient and measurable advertising method. 
    </Typography>
    
    <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify',fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
    PPC provides immediate visibility and results, which can be crucial for businesses looking to quickly increase their online presence.
    </Typography>

    
    </Grid>
    
    </Grid>
  )
}

export default Page3
