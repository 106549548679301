import { Box, Grid, Typography, List, ListItem, Paper, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { ReactTyped } from 'react-typed';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const Carding = () => {

  const cards=[
    {text:'Keyword Research', },
    {text: 'Title Tags',},
    {text: 'Meta Descriptions', },
    {text: 'Header Tags (H1, H2, H3)',},
    {text: 'Image Optimization',},
    {text:    'URL Structure',    },
    {text:    'Internal Linking',},
    {text:     'Mobile Responsiveness'},
    {text:    'Site Speed Optimization'},
    {text:     'Content Quality and Relevance'},
    {text:     'Canonical Tags'},
    {text:     'Schema Markup' },

  ];
  const pages=[
    {text:'Backlink Building' },
    {text: 'Social Media Engagement'},
    {text: 'Influencer Outreach'},
    {text: 'Brand Mentions'},
    {text: 'Guest Blogging'},
    {text: 'Online Reputation Management'},
    {text: 'Content Marketing'},
    {text: 'Forum Participation'},
    {text: 'Social Bookmarking' },
    {text: 'Local Listings and Directories',},
    {text: 'Video Marketing'},
    {text: 'Podcast Promotion'},

  ];
  return (
    <Grid container  spacing={3} sx={{
      backgroundColor: 'white',
      mb:4,
      padding: '2rem'
    }}>
<Grid item xs={12} md={3}
sx={{justifyContent:'center', color:'purple', padding:'10px 10px'}}>
 <Typography variant="h4" gutterBottom sx={{ marginTop: { md: 30, xs: 4 }, fontWeight: 'bold', marginLeft: { md: 3, xs: 0 } }} >
        <ReactTyped
          strings={["Discover our SEO Services"]}
          typeSpeed={60}
          backSpeed={50}
          loop
          style={{ color: 'purple', fontWeight: 'bold', fontSize: '50px' }} 
        />
      </Typography>
</Grid>
<Grid item xs={12} sm={4.5} display="flex" sx={{ marginTop: "10px" }}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: '10px' }}>
            <Box sx={{ backgroundColor: '#a2b8a0', color: '#fff', padding: '1rem', borderRadius: '10px 10px 0 0' }}>
              <Typography variant="h6" align="center">
                On-Page SEO
              </Typography>
            </Box>
            <List sx={{ backgroundColor: '#fff', padding: '1rem', borderRadius: '0 0 10px 10px', flexGrow: 1 }}>
              {cards.map((cards, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: '#6A1B9A' }} />
                  </ListItemIcon>
                  <ListItemText primary={cards.text}  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4.5} display="flex" sx={{ marginTop: "10px" }}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: '10px' }}>
            <Box sx={{ backgroundColor: '#6c9ea8', color: '#fff', padding: '1rem', borderRadius: '10px 10px 0 0' }}>
              <Typography variant="h6" align="center">
                Off-Page SEO
              </Typography>
            </Box>
            <List sx={{ backgroundColor: '#fff', padding: '1rem', borderRadius: '0 0 10px 10px', flexGrow: 1 }}>
              {pages.map((pages, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon sx={{ color: 'purple' }} />
                  </ListItemIcon>
                  <ListItemText primary={pages.text}  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

    </Grid>
  )
}

export default Carding
