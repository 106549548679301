import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { AccessTime, Search, Visibility, Speed, Group } from '@mui/icons-material'; // Importing example icons
import banner2 from '../../../../assets/banner2.png';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';



const Courses = () => {

  const content = [
    {
      icon: <PhotoCameraFrontIcon sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the first item
      heading: "Expert-Led Training",
      paragraph: "Our content writing courses are led by industry professionals with years of real-world experience. Learn directly from experts about the latest trends and strategies in content writing, storytelling, and digital copywriting."
    },
    {
      icon: <EngineeringIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the second item
      heading: "Hands-On, Practical Learning",
      paragraph: "We emphasize practical, hands-on training over theory. You’ll work on real projects, refine your writing style, and develop the skills necessary to create impactful content. Through live exercises, you’ll gain confidence in applying these techniques in the industry."
    },
    {
      icon: <ArticleIcon  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the third item
      heading: "Updated Curriculum",
      paragraph: "Our course material is regularly updated to keep pace with the ever-evolving field of content writing and digital marketing. You’ll learn the latest methods, tools, and approaches that reflect current industry standards."
    },
    {
      icon: <Speed  sx={{fontSize:60,  borderRadius:'10px'}} />, // Icon for the fourth item
      heading: "Comprehensive Content Writing Scope",
      paragraph: "Our training covers a wide range of content writing essentials, from crafting engaging blog posts and articles to writing for social media and websites. Learn how to create content that’s optimized, accessible, and user-friendly."
    },
    {
      icon: <Group sx={{fontSize:60,  borderRadius:'10px'}}  />, // Icon for the fifth item
      heading: "Targeted Audience Engagement",
      paragraph: "With content writing skills, you can reach and resonate with specific audiences more effectively. Learn how to tailor content to engage people who are genuinely interested in your products or services, ensuring your message connects and converts."
    },
  ];

  return (
    <Box
      sx={{
        width: '100vw',
        backgroundColor: '#f5f5f5',
        padding: '30px 0px',
        overflowX: 'hidden',
      }}
    >
      <Container sx={{ minHeight: '80vh' }}>
        <Typography color="textSecondary" variant="h6" textAlign="center">
          Why Content Writing Drives Digital Success
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#4b87a6',
            textAlign: 'center',
            mb: 3,
            fontWeight: 600,
            fontFamily: 'Arial, sans-serif',
            fontSize: '2.5rem',
          }}
        >
          Few Reasons to Choose Us for Content Writing
        </Typography>

        <Grid container spacing={5} alignItems="center" justifyContent="left" padding="20px">
          <Grid item xs={12} md={6}>          
            <img
              src={banner2}
              alt="banner2"
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ fontFamily: 'cursive', textAlign: 'justify' }}>
            <Box
              sx={{
                maxHeight: '600px',
                overflowY: 'auto',
                '&:hover': {
                  overflowY: 'scroll',
                },
                '&::-webkit-scrollbar': {
                  display: 'none', // Hide scrollbar in WebKit browsers
                },
                msOverflowStyle: 'none',  // Hide scrollbar in IE and Edge
                scrollbarWidth: 'none',  // Hide scrollbar in Firefox
              }}
            >
              {content.map((item, index) => (
                <Box key={index} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ marginRight: '15px', color: '#907394' , mt:2}}>{item.icon}</Box>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: 1 }}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '15px' ,fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
                      {item.paragraph}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Courses;
