import React from 'react';
import { Typography, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import Servicebanner2 from '../../../assets/Servicebanner2.png';
import Servicebanner1 from "../../../assets/Servicebanner1.png";
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

const slidesData = [
  {
    title: "Unlock the potential of your business",
    description: "Optimize your company's growth and success by transforming it with creative ideas and professional advice.",
    image: Servicebanner1,
    buttonText: "Explore More",
  },
  {
    title: "Accept knowledge, appreciate achievement.",
    description: "Accept education and view each success as a step toward personal development.",
    image: Servicebanner2,
    buttonText: "Discover More",
  }
];

// Custom arrow component
const Arrow = ({ className, style, onClick, direction }) => {
  return (
    <Box
      className={className}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#046678',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: 1,
        width: '40px',
        height: '40px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        [direction === 'left' ? 'left' : 'right']: '40px',
      }}
      onClick={onClick}
    >
      {/* [direction === 'left' ? 'left' : 'right']: '40px', */}
      {direction === 'left' ? (
        <ArrowCircleLeftRoundedIcon fontSize="large"  />
      ) : (
        <ArrowCircleRightRoundedIcon fontSize="large" />
      )}
    </Box>
  );
};

// Settings for the carousel
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  // prevArrow: <Arrow direction="left"  color='#004e6a'/>,
  // nextArrow: <Arrow direction="right" color='#004e6a'/>
};

const Servicebanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        padding: '10px',
        background: '#e8edfc',
        width: '100%',
        height:'60vh'
      }}
    >
      <Slider {...sliderSettings}>
        {slidesData.map((slide, index) => (
          <Box key={index}>
            <Box
              sx={{
                display: 'flex',
                m:1,
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? '20px' : '60px',
              }}
            >
              <Box sx={{ width: isMobile ? '100%' : '40%', textAlign: isMobile ? 'center' : 'left', padding: '10px' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  {slide.title}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  {slide.description}
                </Typography>
                {/* <Button variant="contained" sx={{ borderRadius: '40px',  background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)', color: 'white' }}>
                  {slide.buttonText}
                </Button> */}
              </Box>
              <Box sx={{ width: isMobile ? '100%' : '40%', textAlign: isMobile ? 'center' : 'right', padding: '10px' }}>
                <img
                  src={slide.image}
                  alt={slide.title}
                  style={{ width: '100%', height: '300px', borderRadius: '60px' }}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Servicebanner;
