import { Grid } from '@mui/material'
import React from 'react'
import Frontpage from '../softwaretesting/Homepage/Frontpage1';
import Header1 from '../../Page/Header1';
import Footer from '../../Page/Footer';
import Courses from '../softwaretesting/Homepage/Courses';

const Alltest = () => {
  return (
    <Grid>
        <Header1/>  
      <Frontpage/>
      <Courses/>
      <Footer/>
    </Grid>
  )
}

export default Alltest
