import React, { useState } from 'react';
import { Grid, TextField, Button, InputAdornment, IconButton, Box, Snackbar, Alert } from '@mui/material';
import banner1 from '../../assets/banner1.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Lagin() {
  const [laginData, setLagindata] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLagindata({
      ...laginData,
      [name]: value,
    });
  };
  const handleChanges = (e) => {
    const { email, password, value } = e.target;
    setLagindata({
      ...laginData,
      [email]: value,
      [password]: value,
    });

  }
  const handleClick = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };



  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${banner1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Full screen height
        width: '100vw',  // Full screen width
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center the form
      }}

    >
      <Box sx={{
  position: 'absolute', 
  top: 0, 
  left: 0, 
  padding: 2,  // Adjust padding as needed
}}>
  <Button variant="contained">Home</Button>
</Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{
            textAlign: 'center',
            backgroundColor: "white",
            color: "purple",
          }}
        >
          Login Successfully
        </Alert>
      </Snackbar>
     
      <Grid
        container
        sx={{
          display: 'table',
          backgroundColor: 'white', // Add semi-transparent background
          padding: '20px',
          height: '400px',
          width: '400px', // Define form width
        }}
        justifyContent="center"
      >

        <Grid item sx={{ marginBottom: '30px', textAlign: 'center' }}>
          <h2>Login</h2>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '30px' }}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            name='email'
            variant="outlined"
            value={laginData.email}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '30px' }}>
  <TextField
    fullWidth
    label="Password"
    name="password"
    type={showPassword ? 'text' : 'password'}
    value={laginData.password}
    onChange={handleChange}
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleTogglePassword} edge="end">
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Grid>


        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button variant="contained" color="success" fullWidth sx={{
            marginBottom: '30px', height: '50px', borderRadius: '20px', backgroundColor: '#E0E7FF',
            color: '#3f51b5',
            mt: 2,
            textTransform: 'none',
            transition: 'color 0.5s ease, background-color 0.5s ease',
            '&:hover': {
              backgroundColor: '#3f51b5',
              color: '#E0E7FF',
            },
          }} onClick={handleClick}>
            Login
          </Button>

          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: '#E0E7FF',
              color: '#3f51b5',
              borderRadius: 1,
              mt: 2,
              textTransform: 'none',
              transition: 'color 0.5s ease, background-color 0.5s ease',
              '&:hover': {
                backgroundColor: '#3f51b5',
                color: '#E0E7FF',
              },
            }}
            endIcon={
              <Box component="span" className="arrowIcon" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 'inherit' }}>
                →
              </Box>
            }
          >
            READ MORE
          </Button> */}
        </Grid>
      </Grid>
      
    </Grid>
  );
}

export default Lagin;
