import React from 'react'
import { Grid, Typography,useTheme,  useMediaQuery} from '@mui/material'
import banner2 from '../../../../assets/training/Emailmarketing.jpeg';
import { motion } from 'framer-motion'; 
import { useInView } from 'react-intersection-observer';

const Page3 = () => {
  const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.8 });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  return (
    <Grid container sx={{padding:'20px 50px',backgroundColor:'#fff' , mb:4}} >
    <Grid item sx={12} md={6} >
    <motion.div
                          ref={cardOneRef}
                          initial={{ opacity: 0, y: 50 }}
                          animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
                          transition={{ duration: 1, delay: 0 }} // First card appears immediately
                      >
          <img
            src={banner2}
            alt="banner2"
            style={{ width: isMobile? '100%':'90%', height: 'auto', borderRadius: '10px' ,}}
          />
          </motion.div>
      </Grid>
      
    <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify', }}>
    <Typography variant="h2" sx={{
                          color: '#4b87a6',
                          textAlign: 'left',
                          mt:2,
                          mb: 3,
                          fontWeight: 600,
                          fontFamily: 'Arial, sans-serif',
                          fontSize: { xs: '1.8rem', sm: '2rem' , md:'40px' }, // Responsive font size
                      }}>
Engage and Convert Your Audience                          </Typography>
<Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' , fontSize: { xs: '15px', sm: '18px' , md:'18px'}}}>
   
In-depth knowledge and real-world experience are provided by our email marketing training classes to help you master the art of crafting effective email campaigns.

   </Typography>
   
   <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify',fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
   To increase engagement and encourage conversions learn how to create engaging content, divide up your audience, and examine important KPIs.   
   </Typography>

   <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify',fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
   Through practical experience and informed guidance our training equips you with the skills you need to develop successful email campaigns automate procedures, and maximize return on investment.  
   </Typography>
    
    </Grid>
    
    </Grid>
  )
}

export default Page3
