import React, { useState } from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CampaignIcon from '@mui/icons-material/Campaign';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/joy/Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner2 from '../../assets/banner2.png';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .slick-prev, & .slick-next': {
    zIndex: 1,
    width: 30,
    height: 30,
    '&:before': {
      fontSize: 30,
      color: 'white',
    },
  },
  '& .slick-prev': {
    left: -40,
    [theme.breakpoints.down('sm')]: {
      left: -20,
    },
  },
  '& .slick-next': {
    right: -80,
    [theme.breakpoints.down('sm')]: {
      right: -20,
    },
  },
  '& .slick-slide': {
    transform: 'scale(0.8)', // Reduce size for all slides
    transition: 'transform 0.5s ease-in-out',
  },
  '& .slick-active.slick-center + .slick-active': {
    transform: 'scale(1.0)', // First slide after center
  },
  '& .slick-active.slick-center ~ .slick-active + .slick-active': {
    transform: 'scale(0.8)', // Second slide after center
  },
}));

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const handleClick = () => {
    navigate('/allservices');
  };

  const cards = [
    {
      title: 'Search Engine Optimization ',
      icon: <DesignServicesIcon />,
      description: 'Boost organic traffic to your website with on-page SEO, backlink building, and keyword strategies.',
    },
    {
      title: 'Social Media Management',
      icon: <CampaignIcon />,
      description: 'Engage customers and build brand exposure through social media on platforms like Facebook and Twitter.',
    },
    {
      title: 'Content Creation',
      icon: <BusinessCenterIcon />,
      description: 'Create high-quality content such as blogs, articles, and videos to enhance your brand’s online presence.',
    },
    {
      title: 'Google ADS (PPC)',
      icon: <AdsClickIcon />,
      description: 'Drive targeted traffic and maximize ROI with our expertly managed Google Ads campaigns.',
    },
    {
      title: 'Web Development',
      icon: <BusinessCenterIcon />,
      description: 'Building responsive and interactive websites to maximize user engagement and conversions.',
    },
  ];

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Default to 5 cards
    centerMode: true, // Enable center mode
    centerPadding: '0px', // No extra padding around the center card
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Media Queries
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1024px)');

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${banner2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: isTablet ? '20vh' :'90vh',
      }}
    >
      <Container>
        <Grid container sx={{ paddingTop: 10 }} spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'sans-serif',
                fontSize: 20,
                color: '#7bc999',
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              OUR SERVICES
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'sans-serif',
                fontWeight: 800,
                marginTop: 2,
                color: '#fff',
                textAlign: 'center',
              }}
            >
              We Provide Expert Solutions <br /> For <span style={{ color: '#7bc999' }}>Your Success</span>
            </Typography>
          </Grid>
        </Grid>

        <StyledSlider {...settings} sx={{ marginTop: 4 }}>
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} >
              <Card
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  transform: hoveredCard === index ? 'scale(1.05)' : 'scale(0.9)',
                  transition: 'transform 0.3s',
                  margin: '10px',
                  textAlign: 'center',
                  padding: isMobile ? 3 : 2,
                  position: 'relative',
                  right: isMobile ? '10%' : 0,
                  boxShadow: 3,
                  width: "100%",
                  height: isTablet ? 350 : 400, // Adjust height for tablet version
                  gap: 2,
                  backgroundColor: hoveredCard === index ? '#83bfc9' : '#fff',
                  '&:hover': {
                    boxShadow: 6,
                    backgroundColor: '#83bfc9',
                    color: '#fff',
                  },
                }}
              >
                <CardMedia>
                  <Grid sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                    <Grid
                      sx={{
                        width: 60,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: hoveredCard === index ? '#fff' : '#51915d',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      {React.cloneElement(card.icon, {
                        sx: {
                          fontSize: 30,
                          color: hoveredCard === index ? '#51915d' : '#fff',
                          transition: 'color 0.3s',
                        },
                      })}
                    </Grid>
                  </Grid>
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                      color: hoveredCard === index ? '#ebebeb' : '#7f3ff2',
                      transition: 'color 0.3s',
                      marginBottom: 1,
                      height: 80,
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '18px',
                      color: hoveredCard === index ? '#fff' : '#000',
                      transition: 'color 0.3s',
                      height: 100,
                    }}
                  >
                    {card.description}
                  </Typography>
                </CardContent>
                <Button
                  variant="contained"
                  sx={{
                    marginBottom: '30px',
                    height: '50px',
                    borderRadius: '20px',
                    // background: "linear-gradient(90deg, #205deb 0%, #3a93f3 50%, #53c7fa 100%)",
                     background: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2)',
                    color: 'white',
                    mt: isTablet?2: 6,
                    textTransform: 'none',
                    transition: 'color 0.5s ease, background-color 0.5s ease',
                    '&:hover': {
                      backgroundColor: 'linear-gradient(to right, #004e6a, #007B8A, #66B2B2);',
                      color: 'black',
                    },
                  }}
                  onClick={handleClick}
                >
                  Read More
                  <ArrowRightIcon sx={{ marginLeft: 1 }} />
                </Button>
              </Card>
            </Grid>
          ))}
        </StyledSlider>
      </Container>
    </Box>
  );
};

export default Services;
