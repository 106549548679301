import React, { useState, useEffect } from 'react';
import {useMediaQuery, Grid, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const CircularCounter = ({ value, label, bodyLine }) => {
    return (
        <Box sx={{ textAlign: 'center', margin: 2 }}>
            <CircularProgressWithLabel value={value} size={100} thickness={5} sx={{ color: '#4e8c96', fontWeight: 600 }} />
            <Typography variant="h6" sx={{ fontFamily: 'sans-serif', fontWeight: 600, color: 'black', marginTop: 2 }}>{label}</Typography>
            <Typography variant="body1">{bodyLine}</Typography>
        </Box>
    );
};

const StyledContainer = styled('div')({
    perspective: '1000px',
});

const StyledIframe = styled('iframe')({
    width: '100%',
    height: '100%',
});



const Choose = () => {
    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);

    useEffect(() => {
        const timer1 = setInterval(() => {
            setProgress1((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 1));
        }, 30);

        const timer2 = setInterval(() => {
            setProgress2((prevProgress) => (prevProgress >= 95 ? 95 : prevProgress + 1));
        }, 30);

        return () => {
            clearInterval(timer1);
            clearInterval(timer2);
        };
    }, []);

    const isMobile = useMediaQuery('(max-width:600px)');


    return (
        <Grid container display="flex" sx={{ backgroundColor: '#f0f8ff', padding: 3, mb: -8 }} >
            <Grid item md={6} sx={{ px: {md:'30px', xs:'10px'} }}>
                <Typography variant="h4" sx={{ marginTop: 5, marginBottom: 2, fontFamily: 'sans-serif', fontWeight: 700, fontSize: 20, color: 'purple' }}>
                    WHY CHOOSE US
                </Typography>
                <Typography variant="h2" sx={{ fontWeight: 800, fontSize: '30px', fontFamily: 'sans-serif' }}>
                    Over 5+ Years of Excellence in
                    <Typography variant="h2" sx={{ fontWeight: 800, fontSize: '30px', fontFamily: 'sans-serif' }}>
                        customised<span style={{ color: '#4e8c96', marginLeft: 9 }}> Solutions</span>
                    </Typography>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: '15px',  textAlign: 'justify' }}>
                    At 'GetMoreDigital' We take great satisfaction in our more than five years of experience in offering creative personalized
                    digital solutions. Our hardworking team is committed to meeting your specific business goals with results of the highest caliber.
                    We put a lot of effort into comprehending your problems and developing winning solutions so that your projects turn out beautifully.
                </Typography>
                <Grid container display="flex" justifyContent="space-between"
                    sx={{
                        marginLeft: { md: 3, sm:3, xs:'none'},
                        marginTop: { md: 3, xs: 3,  },
                        marginBottom: 10,
                        flexDirection:{md:'row', sm:'row', xs:'row'},
                        padding:{sm:'20px', md:0, xs: 'none'}
                    }}>
                    <Grid md={6} item >
                        <CircularCounter value={progress1} label="Business Growth" bodyLine="Business Activity and Revenue." />
                    </Grid>
                    <Grid md={6} item sx={{ml:{sm:'10', md:'none', xs:'none'}}}>
                        <CircularCounter value={progress2} label="Efficiency Score" bodyLine="Effectiveness and Optimization." />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#83bfc9',  mr:{md:0, xs:4}, ml:{xs:3, md:0, sm:6}}}>
                <StyledContainer sx={{ position: 'relative', bottom: {xs:'45%',md:'0', sm:'45%'},display: 'flex', justifyContent: 'center', alignItems: 'center', width:{xs:'100%', sm:'100%',md:'80%'}, height:{xs:'100%', sm:'100%',md:'80%'} }}>
                    <StyledIframe
                        src="https://www.youtube.com/embed/nBXBZdPK-lo" // Change to embed link
                        title="YouTube Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{borderRadius:'20px'}}
                    />
                </StyledContainer>
            </Grid>
        </Grid>
    );
};

export default Choose;
