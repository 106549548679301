import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import image from '../../../assets/newarticlesbackgroundimae.png';

const Featured = () => {

    const post = [
        { title: 'Java' },
        { title: 'Python' },
        { title: 'JavaScript' },
        { title: 'Artificial Intelligence' },
        { title: 'Database' },
        { title: 'React JS' },
        { title: 'React Native' },
        { title: 'HTML & CSS' },
        // { title: 'Angular JS' },
        // { title: 'Machine Learning' },
        // { title: 'Ruby' },
    ];

    return (
        <Grid
            container
            sx={{
                // backgroundColor: 'white',
               
                minHeight: '50vh',
                py: 4,
                justifyContent: 'center',
            }}
        >
            <Grid item xs={12} md={12}>
                <Typography variant="h3" sx={{ fontWeight: 600, mt: 1, textAlign: 'center', color: '#4b87a6' }}>
                    Popular Topics
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 400, mt: 1, textAlign: 'center', color: 'black' }}>
                Everything you need at one location
                </Typography>
            </Grid>

            <Grid 
                container 
                item 
                xs={12} 
                spacing={2} // Adds space between grid items
                sx={{ padding: '40px 20px',  backgroundImage:`url(${image})`, mt:2 }}
                
            >
                {post.slice(0, 10).map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}> {/* Adjusted for responsive design */}
                        <Card sx={{ width: '100%', backgroundColor: 'transparent' , padding:'3px 5px'}}>
                            <CardContent>
                                <Typography variant="h5" sx={{ fontFamily: 'sans-serif', fontWeight: 800, mt: 4, textAlign: 'center' , color:'black'}}>
                                    {item.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default Featured;
