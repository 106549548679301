import React from 'react'
import { Grid, Typography,useTheme, useMediaQuery } from '@mui/material'
import banner2 from '../../../../assets/training/seo.jpg';
import { motion } from 'framer-motion'; 
import { useInView } from 'react-intersection-observer';

const Page3 = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const { ref: cardOneRef, inView: cardOneInView } = useInView({ triggerOnce: true, threshold: 0.8 });
  return (
    <Grid container sx={{padding:'20px 50px',backgroundColor:'#fff' }} >
      <Grid item sx={12} md={6} >
      <motion.div
                            ref={cardOneRef}
                            initial={{ opacity: 0, y: 50 }}
                            animate={cardOneInView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 1, delay: 0 }} // First card appears immediately
                        >
            <img
              src={banner2}
              alt="banner2"
              style={{ width: isMobile? '100%':'90%', height: 'auto', borderRadius: '10px' ,}}
            />
            </motion.div>
        </Grid>
        
      <Grid item xs={12} md={6} sx={{ fontFamily:'cursive', justifyContent:"center", textAlign: 'justify', }}>
      <Typography variant="h2" sx={{
                            color: '#4b87a6',
                            textAlign: 'left',
                            mt:2,
                            mb: 3,
                            fontWeight: 600,
                            fontFamily: 'Arial, sans-serif',
                            fontSize: { xs: '1.8rem', sm: '2rem' , md:'2rem' }, // Responsive font size
                        }}>
                               Why SEO Drives Digital Success
                            </Typography>
      <Typography variant="body1" sx={{ marginTop: '15px',textAlign: 'justify', fontSize: { xs: '15px', sm: '18px' , md:'18px'} }}>
      Search Engine Optimization (SEO) is the cornerstone of effective digital marketing. 
      SEO can help boost your authority and credibility online, because the higher that your website ranks on search engines, 
      the more trusted your business will seem to both your target audience and search engine crawlers.
      </Typography>
      
      <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' , fontSize: { xs: '15px', sm: '18px' , md:'18px'}}}>
      SEO can help you outrank your competitors, which can increase brand awareness and attract new customers.
      </Typography>
      
      <Typography variant="body1" sx={{ marginTop: '15px', textAlign: 'justify' }}>
      Optimizing your site's structure, content, and navigation for SEO can make it more accessible to users. 
      </Typography>

      
      </Grid>
      
      </Grid>
  )
}

export default Page3
